const NewPricingTab = ({ pricingTabs = [], activePricingTab, setActivePricingTab }) => {
    return (
        <div className="jlt-grid jlt-grid-cols-3 jlt-gap-4 lg:jlt-gap-6 xl:jlt-mx-[250px] jlt-bg-[#F7FAFC] jlt-border jlt-border-[#EDF2F7] jlt-rounded-lg jlt-p-[6px] ">
            {pricingTabs.map((tab, index) => (
                <div
                    className={`jlt-cursor-pointer jlt-select-none jlt-py-4 lg:jlt-py-[10px] jlt-rounded-md ${
                        activePricingTab === tab.id ? "jlt-bg-[#FFFFFF] jlt-shadow" : ""
                    } jlt-group/ptab hover:jlt-bg-[#FFFFFF] hover:jlt-shadow`}
                    key={tab.id || index}
                    onClick={() => setActivePricingTab(tab.id)}
                >
                    <h3
                        className={`jlt-text-base jlt-font-bold jlt-leading-[20px] jlt-text-center ${
                            activePricingTab === tab.id
                                ? "jlt-text-[#3A3AE9]"
                                : "jlt-text-[#1F2733]"
                        } group-hover/ptab:jlt-text-[#3A3AE9]`}
                    >
                        {tab.title}
                    </h3>
                    <span
                        className={`jlt-hidden lg:jlt-block jlt-text-[13px] jlt-font-medium jlt-leading-[20px] jlt-text-center jlt-mt-1 ${
                            activePricingTab === tab.id
                                ? "jlt-text-[#3A3AE9]"
                                : "jlt-text-[#1F2733]"
                        } group-hover/ptab:jlt-text-[#3A3AE9]`}
                    >
                        {tab.description}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default NewPricingTab;
