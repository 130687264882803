"use client";

import { useState } from "react";

const Checkbox = ({ field }) => {
    const [checkItems, setCheckItems] = useState([]);

    return (
        <div className="jlt-py-2">
            <span className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">{field.settings.label}</span>
            <div className="jlt-flex jlt-items-center jlt-gap-6 jlt-mt-2">
                {field.settings.advanced_options.map((radio) => (
                    <div key={radio.value} className="jlt-flex jlt-gap-1">
                        <input
                            onChange={(e) => setCheckItems([...checkItems, e.target.value])}
                            type={field.attributes.type}
                            id={radio.value}
                            // name={field.attributes.name}
                            placeholder={field.attributes.placeholder}
                            value={radio.value}
                        />
                        <input className="jlt-hidden" name={field.attributes.name} defaultValue={`${checkItems}`}></input>
                        <label htmlFor={radio.value}>{radio.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Checkbox;
