import PricingTooltip from "../PricingTooltip";

const Lifetime = () => {
    return (
        <div className="jlt-grid jlt-gap-8 lg:jlt-gap-0 jlt-grid-cols-1 lg:jlt-grid-cols-[auto_200px_200px_200px] xl:jlt-grid-cols-[auto_260px_260px_260px] jlt-mt-8 jlt-border-0 lg:jlt-border jlt-border-[#E2E8F0] [&>*]:jlt-rounded-lg lg:[&>*]:jlt-rounded-none  [&>*]:jlt-border jlt-rounded lg:[&>*]:jlt-border-[0]  lg:[&>*]:jlt-border-r [&>*]:jlt-border-[#E2E8F0] last:jlt-border-r-0">
            <div className="jlt-select-none jlt-hidden lg:jlt-block">
                <div className="jlt-flex jlt-items-center jlt-justify-center jlt-h-[306px]">
                    <img
                        className="jlt-w-[230px]"
                        src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2021/09/12100952/money-back-badge.svg"
                        alt="Money Back Guaranty"
                    />
                </div>
                <ul className="[&>*:nth-child(odd)]:jlt-bg-[#F7FAFC]">
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <a
                            href=""
                            className="jlt-text-sm jlt-text-[#1A202C] jlt-font-semibold jlt-leading-6">
                            Websites
                        </a>
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Backend & Frontend Access
                        </span>
                        <PricingTooltip content="Access WP Spotlight Search on both the backend and frontend" />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            User Access & Management
                        </span>
                        <PricingTooltip content="Quickly search, edit, and manage users by role or email—without extra clicks." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Plugin Management
                        </span>
                        <PricingTooltip content="Search, install, activate, deactivate, or delete plugins directly—no need to navigate to the dashboard." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Post & Content Management
                        </span>
                        <PricingTooltip content="Edit, view, trash, or delete posts instantly with builder support for Elementor, Gutenberg, and more." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Multisite Switching
                        </span>
                        <PricingTooltip content="One-click access to all sites and network admin—no extra navigation." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Customizable Key Binds
                        </span>
                        <PricingTooltip content="Create personalized keyboard shortcuts for WP Spotlight actions to streamline your workflow." />
                    </li>
                </ul>
            </div>
            <div className="jlt-select-none">
                <div className="jlt-py-6 jlt-px-4">
                    <h4 className="jlt-text-[18px] jlt-text-[#3A3AE9] jlt-font-bold jlt-leading-[32px] jlt-text-center">
                        Basic
                    </h4>
                    <span class="jlt-flex jlt-justify-center jlt-gap-[2px] jlt-text-[32px] jlt-text-[#171923] jlt-font-bold jlt-leading-[40px] jlt-text-center jlt-mt-4">
                        $49
                        <sub className="jlt-relative jlt-top-[10px] jlt-text-[14px] jlt-text-[#4A5568] jlt-font-medium jlt-leading-[24px]">
                            /lifetime
                        </sub>
                    </span>
                    <span class="jlt-block jlt-text-base jlt-text-[#4A5568] jlt-font-medium jlt-leading-[28px] jlt-mt-1 jlt-text-center">
                        Billed Annually
                    </span>
                    <span class="jlt-block jlt-text-[14px] jlt-text-[#718096] jlt-font-medium jlt-leading-[24px] jlt-text-center jlt-mt-4">
                        Updates & support for
                    </span>
                    <select
                        name=""
                        id="plan-selector"
                        className="jlt-py-[6px] jlt-px-2 jlt-border jlt-border-[#1F1FC1] jlt-rounded jlt-outline-none jlt-bg-[#fff] jlt-w-[100%] jlt-mt-1 jlt-text-[13px] jlt-text-[#3A3AE9] jlt-font-semibold jlt-leading-[20px]">
                        <option value="1 year">1 year</option>
                        <option value="2 year">2 year</option>
                        <option value="lifetime" selected>
                            Lifetime
                        </option>
                    </select>
                    <div className="jlt-flex jlt-justify-center jlt-mt-4">
                        <button
                            type="button"
                            className="jlt-py-3 jlt-px-8 jlt-bg-[#3A3AE9] jlt-border-[1px] jlt-border-[#3A3AE9] jlt-rounded-lg jlt-text-[16px] jlt-text-[#FFFFFF] jlt-font-bold jlt-leading-[20px] hover:jlt-bg-transparent hover:jlt-text-[#3A3AE9]">
                            Buy Now
                        </button>
                    </div>
                </div>
                <ul className="[&>*:nth-child(odd)]:jlt-bg-[#F7FAFC] jlt-hidden lg:jlt-block">
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-block jlt-text-[16px] jlt-text-[#1F2733] jlt-font-semibold jlt-text-center jlt-leading-[23px]">
                            1
                        </span>
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                </ul>
                <ul className="[&>*:nth-child(odd)]:jlt-bg-[#F7FAFC] jlt-block lg:jlt-hidden">
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <a
                            href=""
                            className="jlt-text-sm jlt-text-[#1A202C] jlt-font-semibold jlt-leading-6">
                            Websites 1
                        </a>
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Backend & Frontend Access
                        </span>
                        <PricingTooltip content="Access WP Spotlight Search on both the backend and frontend" />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            User Access & Management
                        </span>
                        <PricingTooltip content="Quickly search, edit, and manage users by role or email—without extra clicks." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Plugin Management
                        </span>
                        <PricingTooltip content="Search, install, activate, deactivate, or delete plugins directly—no need to navigate to the dashboard." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Post & Content Management
                        </span>
                        <PricingTooltip content="Edit, view, trash, or delete posts instantly with builder support for Elementor, Gutenberg, and more." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Multisite Switching
                        </span>
                        <PricingTooltip content="One-click access to all sites and network admin—no extra navigation." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Customizable Key Binds
                        </span>
                        <PricingTooltip content="Create personalized keyboard shortcuts for WP Spotlight actions to streamline your workflow." />
                    </li>
                </ul>
            </div>
            <div className="jlt-select-none">
                <div className="jlt-py-6 jlt-px-4">
                    <h4 className="jlt-text-[18px] jlt-text-[#3A3AE9] jlt-font-bold jlt-leading-[32px] jlt-text-center">
                        Basic
                    </h4>
                    <span class="jlt-flex jlt-justify-center jlt-gap-[2px] jlt-text-[32px] jlt-text-[#171923] jlt-font-bold jlt-leading-[40px] jlt-text-center jlt-mt-4">
                        $49
                        <sub className="jlt-relative jlt-top-[10px] jlt-text-[14px] jlt-text-[#4A5568] jlt-font-medium jlt-leading-[24px]">
                            /lifetime
                        </sub>
                    </span>
                    <span class="jlt-block jlt-text-base jlt-text-[#4A5568] jlt-font-medium jlt-leading-[28px] jlt-mt-1 jlt-text-center">
                        Billed Annually
                    </span>
                    <span class="jlt-block jlt-text-[14px] jlt-text-[#718096] jlt-font-medium jlt-leading-[24px] jlt-text-center jlt-mt-4">
                        Updates & support for
                    </span>
                    <select
                        name=""
                        id="plan-selector"
                        className="jlt-py-[6px] jlt-px-2 jlt-border jlt-border-[#1F1FC1] jlt-rounded jlt-outline-none jlt-bg-[#fff] jlt-w-[100%] jlt-mt-1 jlt-text-[13px] jlt-text-[#3A3AE9] jlt-font-semibold jlt-leading-[20px]">
                        <option value="1 year">1 year</option>
                        <option value="2 year">2 year</option>
                        <option value="lifetime" selected>
                            Lifetime
                        </option>
                    </select>
                    <div className="jlt-flex jlt-justify-center jlt-mt-4">
                        <button
                            type="button"
                            className="jlt-py-3 jlt-px-8 jlt-bg-[#3A3AE9] jlt-border-[1px] jlt-border-[#3A3AE9] jlt-rounded-lg jlt-text-[16px] jlt-text-[#FFFFFF] jlt-font-bold jlt-leading-[20px] hover:jlt-bg-transparent hover:jlt-text-[#3A3AE9]">
                            Buy Now
                        </button>
                    </div>
                </div>
                <ul className="[&>*:nth-child(odd)]:jlt-bg-[#F7FAFC] jlt-hidden lg:jlt-block">
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-block jlt-text-[16px] jlt-text-[#1F2733] jlt-font-semibold jlt-text-center jlt-leading-[23px]">
                            5
                        </span>
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                </ul>
                <ul className="[&>*:nth-child(odd)]:jlt-bg-[#F7FAFC] jlt-block lg:jlt-hidden">
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <a
                            href=""
                            className="jlt-text-sm jlt-text-[#1A202C] jlt-font-semibold jlt-leading-6">
                            Websites 5
                        </a>
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Backend & Frontend Access
                        </span>
                        <PricingTooltip content="Access WP Spotlight Search on both the backend and frontend" />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            User Access & Management
                        </span>
                        <PricingTooltip content="Quickly search, edit, and manage users by role or email—without extra clicks." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Plugin Management
                        </span>
                        <PricingTooltip content="Search, install, activate, deactivate, or delete plugins directly—no need to navigate to the dashboard." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Post & Content Management
                        </span>
                        <PricingTooltip content="Edit, view, trash, or delete posts instantly with builder support for Elementor, Gutenberg, and more." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Multisite Switching
                        </span>
                        <PricingTooltip content="One-click access to all sites and network admin—no extra navigation." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Customizable Key Binds
                        </span>
                        <PricingTooltip content="Create personalized keyboard shortcuts for WP Spotlight actions to streamline your workflow." />
                    </li>
                </ul>
            </div>
            <div className="jlt-select-none">
                <div className="jlt-py-6 jlt-px-4">
                    <h4 className="jlt-text-[18px] jlt-text-[#3A3AE9] jlt-font-bold jlt-leading-[32px] jlt-text-center">
                        Basic
                    </h4>
                    <span class="jlt-flex jlt-justify-center jlt-gap-[2px] jlt-text-[32px] jlt-text-[#171923] jlt-font-bold jlt-leading-[40px] jlt-text-center jlt-mt-4">
                        $49
                        <sub className="jlt-relative jlt-top-[10px] jlt-text-[14px] jlt-text-[#4A5568] jlt-font-medium jlt-leading-[24px]">
                            /lifetime
                        </sub>
                    </span>
                    <span class="jlt-block jlt-text-base jlt-text-[#4A5568] jlt-font-medium jlt-leading-[28px] jlt-mt-1 jlt-text-center">
                        Billed Annually
                    </span>
                    <span class="jlt-block jlt-text-[14px] jlt-text-[#718096] jlt-font-medium jlt-leading-[24px] jlt-text-center jlt-mt-4">
                        Updates & support for
                    </span>
                    <select
                        name=""
                        id="plan-selector"
                        className="jlt-py-[6px] jlt-px-2 jlt-border jlt-border-[#1F1FC1] jlt-rounded jlt-outline-none jlt-bg-[#fff] jlt-w-[100%] jlt-mt-1 jlt-text-[13px] jlt-text-[#3A3AE9] jlt-font-semibold jlt-leading-[20px]">
                        <option value="1 year">1 year</option>
                        <option value="2 year">2 year</option>
                        <option value="lifetime" selected>
                            Lifetime
                        </option>
                    </select>
                    <div className="jlt-flex jlt-justify-center jlt-mt-4">
                        <button
                            type="button"
                            className="jlt-py-3 jlt-px-8 jlt-bg-[#3A3AE9] jlt-border-[1px] jlt-border-[#3A3AE9] jlt-rounded-lg jlt-text-[16px] jlt-text-[#FFFFFF] jlt-font-bold jlt-leading-[20px] hover:jlt-bg-transparent hover:jlt-text-[#3A3AE9]">
                            Buy Now
                        </button>
                    </div>
                </div>
                <ul className="[&>*:nth-child(odd)]:jlt-bg-[#E9E9F6] jlt-hidden lg:jlt-block">
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <select
                            name=""
                            id="plan-selector"
                            className="jlt-py-[6px] jlt-px-2 jlt-border jlt-border-[#1F1FC1] jlt-rounded jlt-outline-none jlt-bg-[#fff] jlt-w-[100%] jlt-mt-1 jlt-text-[13px] jlt-text-[#3A3AE9] jlt-font-semibold jlt-leading-[20px]">
                            <option value="1 year" selected>
                                50 Websites
                            </option>
                            <option value="2 year">500 Websites</option>
                            <option value="lifetime">1000 Websites</option>
                        </select>
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                    <li className="jlt-flex jlt-items-center jlt-justify-center jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <img
                            src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062856/Tick-icon.svg"
                            alt="tick mark"
                        />
                    </li>
                </ul>
                <ul className="[&>*:nth-child(odd)]:jlt-bg-[#E9E9F6] jlt-block lg:jlt-hidden">
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <select
                            name=""
                            id="plan-selector"
                            className="jlt-py-[6px] jlt-px-2 jlt-border jlt-border-[#1F1FC1] jlt-rounded jlt-outline-none jlt-bg-[#fff] jlt-w-[100%] jlt-mt-1 jlt-text-[13px] jlt-text-[#3A3AE9] jlt-font-semibold jlt-leading-[20px]">
                            <option value="1 year" selected>
                                50 Websites
                            </option>
                            <option value="2 year">500 Websites</option>
                            <option value="lifetime">1000 Websites</option>
                        </select>
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Backend & Frontend Access
                        </span>
                        <PricingTooltip content="Access WP Spotlight Search on both the backend and frontend" />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            User Access & Management
                        </span>
                        <PricingTooltip content="Quickly search, edit, and manage users by role or email—without extra clicks." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Plugin Management
                        </span>
                        <PricingTooltip content="Search, install, activate, deactivate, or delete plugins directly—no need to navigate to the dashboard." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Instant Post & Content Management
                        </span>
                        <PricingTooltip content="Edit, view, trash, or delete posts instantly with builder support for Elementor, Gutenberg, and more." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Multisite Switching
                        </span>
                        <PricingTooltip content="One-click access to all sites and network admin—no extra navigation." />
                    </li>
                    <li className="jlt-relative jlt-flex jlt-items-center jlt-justify-between jlt-px-6 jlt-py-3 jlt-h-[48px]">
                        <span className="jlt-text-sm jlt-text-[#1A202C] jlt-font-normal jlt-leading-6">
                            Customizable Key Binds
                        </span>
                        <PricingTooltip content="Create personalized keyboard shortcuts for WP Spotlight actions to streamline your workflow." />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Lifetime;
