"use client";

import { Fragment } from "react";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";

const Button = ({ attributes }) => {
    return (
        <Fragment>
            <a
                {...(attributes?.anchor && { id: attributes?.anchor })}
                href={`${attributes?.url ? attributes?.url : "#"}`}
                target={`${attributes?.linkTarget ? attributes?.linkTarget : "_self"}`}
                rel={attributes.rel ? attributes?.rel : ""}
                className="btn">
                {attributes?.text}
            </a>
            <style jsx>{`
                .btn {
                    ${getMinifyCSS(`
                        padding: 12px 24px;
                        border-radius: 6px;
                        display: inline-block;
                        border: 1px solid;
                        ${
                            attributes?.style?.color?.background || attributes?.backgroundColor
                                ? `background: ${
                                      attributes?.style?.color?.background || attributes?.backgroundColor === "background"
                                          ? `#fff`
                                          : attributes?.style?.color?.background || attributes?.backgroundColor
                                  };`
                                : ""
                        }
                        ${attributes?.style?.color?.text ? `color: ${attributes?.style?.color?.text};` : ""}
                        ${attributes?.style?.typography?.fontSize ? `font-size: ${attributes?.style?.typography?.fontSize};` : ""}
                        ${attributes?.style?.typography?.fontWeight ? `font-weight: ${attributes?.style?.typography?.fontWeight};` : ""}
                        ${attributes?.style?.typography?.lineHeight ? `line-height: ${attributes?.style?.typography?.lineHeight};` : ""}
                        ${attributes?.style?.border?.radius ? `border-radius: ${attributes?.style?.border?.radius};` : ""}
                    `)}
                }
            `}</style>
        </Fragment>
    );
};

export default Button;
