"use client";

// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import { ratingCount } from "@litonarefin/utils/ratingCount";
import Image from "next/image";
import { Fragment } from "react";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";

const Rating = ({ attributes }) => {
    const { fullStar, halfStar, emptyStar } = ratingCount(attributes?.rating);

    // const allAOS = getAOSAnimation(attributes);

    return (
        <div
            {...(attributes?.anchor && { id: attributes?.anchor })}
            // {...allAOS}
            className="jlt-flex jlt-items-center jlt-justify-center">
            <div className="jlt-relative jlt-flex jlt-items-center jlt-justify-center jlt-w-[349px] jlt-h-[349px] jlt-rounded-full">
                <div className="jlt-rounded-[150px] jlt-flex jlt-items-center jlt-justify-center jlt-flex-col jlt-gap-y-2 jlt-w-[156px] jlt-h-[220px] jlt-bg-white">
                    {attributes?.ratingImageURL ? (
                        <img
                            src={attributes?.ratingImageURL}
                            alt={attributes?.ratingImageAlt || "icon"}
                            width={32}
                            height={32}
                            style={{ width: "auto", height: "auto" }}
                        />
                    ) : null}

                    <h4 className="jlt-m-0">{attributes?.rating || 4.7}</h4>
                    <div className="jlt-flex jlt-items-center jlt-gap-x-1">
                        {fullStar
                            ? [...Array(fullStar).keys()].map((star, index) => (
                                  <svg
                                      key={index}
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M15.9555 5.74401C15.9052 5.59592 15.8127 5.4658 15.6893 5.36971C15.5659 5.27362 15.4171 5.21576 15.2612 5.20328L10.701 4.84093L8.72766 0.472711C8.66482 0.33202 8.5626 0.212523 8.43334 0.128643C8.30408 0.044763 8.15331 8.4692e-05 7.99922 1.20287e-07C7.84513 -8.44514e-05 7.69431 0.0444284 7.56496 0.128166C7.43561 0.211905 7.33326 0.331289 7.27026 0.471912L5.29692 4.84093L0.736723 5.20328C0.583508 5.21542 0.437034 5.27144 0.314823 5.36465C0.192613 5.45785 0.0998421 5.58429 0.0476092 5.72884C-0.00462369 5.87339 -0.0141069 6.02992 0.0202943 6.17972C0.0546954 6.32951 0.131524 6.46623 0.241589 6.5735L3.61154 9.85867L2.4197 15.0196C2.38351 15.1758 2.39511 15.3393 2.45299 15.4888C2.51087 15.6384 2.61237 15.7671 2.7443 15.8582C2.87623 15.9494 3.03251 15.9988 3.19287 16C3.35322 16.0012 3.51023 15.9542 3.64354 15.8651L7.99896 12.9615L12.3544 15.8651C12.4906 15.9555 12.6513 16.0021 12.8148 15.9985C12.9783 15.9949 13.1368 15.9414 13.269 15.845C13.4011 15.7487 13.5006 15.6142 13.554 15.4596C13.6075 15.305 13.6123 15.1378 13.5678 14.9804L12.1048 9.86107L15.7331 6.5959C15.9707 6.38153 16.0579 6.04717 15.9555 5.74401Z"
                                          fill="#F99F4C"
                                      />
                                  </svg>
                              ))
                            : null}

                        {halfStar
                            ? [...Array(halfStar).keys()].map((star, index) => (
                                  <svg
                                      key={index}
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M3.44616 15.4888C3.38827 15.3393 3.37667 15.1758 3.41286 15.0196L4.60471 9.85867L1.23475 6.5735C1.12469 6.46623 1.04786 6.32951 1.01346 6.17972C0.979057 6.02992 0.98854 5.87339 1.04077 5.72884C1.09301 5.58429 1.18578 5.45785 1.30799 5.36465C1.4302 5.27144 1.57667 5.21542 1.72989 5.20328L6.29008 4.84093L8.26342 0.471912C8.32642 0.331289 8.42877 0.211905 8.55812 0.128167C8.68747 0.0444286 8.83829 -8.42119e-05 8.99238 1.19605e-07C9.14647 8.49315e-05 9.29725 0.0447633 9.4265 0.128643C9.55576 0.212523 9.65798 0.33202 9.72083 0.472712L11.6942 4.84093L16.2544 5.20328C16.4103 5.21576 16.5591 5.27362 16.6825 5.36971C16.8058 5.4658 16.8984 5.59592 16.9487 5.74401C17.0511 6.04717 16.9639 6.38153 16.7263 6.5959L13.098 9.86107L14.561 14.9804C14.6054 15.1378 14.6006 15.305 14.5472 15.4596C14.4938 15.6142 14.3943 15.7487 14.2621 15.845C14.13 15.9414 13.9715 15.9949 13.808 15.9985C13.6445 16.0021 13.4838 15.9555 13.3475 15.8651L8.99213 12.9615L4.6367 15.8651C4.5034 15.9542 4.34638 16.0012 4.18603 16C4.02568 15.9988 3.8694 15.9494 3.73746 15.8582C3.60553 15.7671 3.50404 15.6384 3.44616 15.4888ZM9.00001 10.563L11.7407 12.3901L10.8318 9.20982L13.3141 6.97596L10.3579 6.74106L9.00001 3.73511V10.563Z"
                                          fill="#F99F4C"
                                      />
                                  </svg>
                              ))
                            : null}

                        {emptyStar
                            ? [...Array(emptyStar).keys()].map((star, index) => (
                                  <svg
                                      key={index}
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M3.41091 15.0196C3.37472 15.1758 3.38632 15.3393 3.4442 15.4888C3.50208 15.6384 3.60358 15.7671 3.73551 15.8582C3.86744 15.9494 4.02373 15.9988 4.18408 16C4.34443 16.0012 4.50144 15.9542 4.63475 15.8651L8.99017 12.9615L13.3456 15.8651C13.4818 15.9555 13.6425 16.0021 13.806 15.9985C13.9695 15.9949 14.128 15.9414 14.2602 15.845C14.3923 15.7487 14.4918 15.6142 14.5452 15.4596C14.5987 15.305 14.6035 15.1378 14.559 14.9804L13.096 9.86107L16.7243 6.5959C16.9619 6.38153 17.0491 6.04717 16.9467 5.74401C16.8964 5.59592 16.8039 5.4658 16.6805 5.36971C16.5571 5.27362 16.4083 5.21576 16.2524 5.20328L11.6922 4.84093L9.71888 0.472712C9.65603 0.33202 9.55381 0.212523 9.42455 0.128643C9.29529 0.0447631 9.14452 8.48119e-05 8.99043 1.19945e-07C8.83634 -8.43315e-05 8.68552 0.0444285 8.55617 0.128167C8.42682 0.211905 8.32447 0.331289 8.26147 0.471912L6.28813 4.84093L1.72793 5.20328C1.57472 5.21542 1.42824 5.27144 1.30603 5.36465C1.18382 5.45785 1.09105 5.58429 1.03882 5.72884C0.986587 5.87339 0.977104 6.02992 1.01151 6.17972C1.04591 6.32951 1.12274 6.46623 1.2328 6.5735L4.60275 9.85867L3.41091 15.0196ZM6.0397 12.5247L8.99017 10.5578L11.7387 12.3901L10.8298 9.20982L13.3122 6.97596L10.356 6.74106L8.99009 3.71749L7.62445 6.74105L4.52266 6.98752L6.8047 9.21214L6.0397 12.5247Z"
                                          fill="#F99F4C"
                                      />
                                  </svg>
                              ))
                            : null}
                    </div>
                </div>
                <div className="jlt-absolute -jlt-left-16 jlt-w-[29.8rem] jlt-flex jlt-flex-col jlt-justify-between">
                    <div className="jlt-flex jlt-items-center jlt-justify-center jlt-mb-8">
                        <Button
                            img={attributes?.logo1URL}
                            alt={attributes?.logo1Alt}
                            url={attributes?.logo1SiteURL?.url}
                        />
                    </div>
                    <div className="jlt-flex jlt-items-center jlt-justify-between jlt-mb-24">
                        <Button
                            img={attributes?.logo2URL}
                            alt={attributes?.logo2Alt}
                            url={attributes?.logo2SiteURL?.url}
                        />
                        <Button
                            img={attributes?.logo3URL}
                            alt={attributes?.logo3Alt}
                            url={attributes?.logo3SiteURL?.url}
                        />
                    </div>
                    <div className="jlt-flex jlt-items-center jlt-justify-between jlt-mb-8">
                        <Button
                            img={attributes?.logo4URL}
                            alt={attributes?.logo4Alt}
                            url={attributes?.logo4SiteURL?.url}
                        />
                        <Button
                            img={attributes?.logo5URL}
                            alt={attributes?.logo5Alt}
                            url={attributes?.logo5SiteURL?.url}
                        />
                    </div>
                    <div className="jlt-flex jlt-items-center jlt-justify-center">
                        <Button
                            img={attributes?.logo6URL}
                            alt={attributes?.logo6Alt}
                            url={attributes?.logo6SiteURL?.url}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rating;

const Button = ({ img, alt, url }) => {
    return (
        <Fragment>
            <a className="jlt-rating-btn" href={url || "#"} target={url ? "_blank" : "_self"}>
                {img ? (
                    <Image
                        src={img}
                        alt={alt}
                        width={96}
                        height={48}
                        sizes={"100vw"}
                        // style={{ width: "auto", height: "auto" }}
                    />
                ) : null}
            </a>
            <style jsx>{`
                .jlt-rating-btn {
                    ${getMinifyCSS(`
                        padding: 12px 24px;
                        background: #fff;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        border-radius: 40px;
                        box-shadow: none !important;
                        outline: none !important;
                        ${!!url ? "cursor: pointer;" : "cursor: default;"}
                    `)}
                }
                .jlt-rating-btn > img {
                    ${getMinifyCSS(`
                        width: 95px;
                    `)}
                }
            `}</style>
        </Fragment>
    );
};
