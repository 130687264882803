import React from "react";
import PluginInfo from "./components/PluginInfo";

const getData = async (productSlug) => {
    const res = await fetch(
        `https://api.wordpress.org/plugins/info/1.2/?action=plugin_information&request[slug]=${productSlug}&request[downloaded]=true&request[fields][contributors]=0&request[fields][sections]=0&request[fields][screenshots]=0&request[fields][tags]=0&request[fields][versions]=0&request[fields][banners]=0&request[fields][preview_link]=0&request[fields][tested]=0&request[fields][requires_plugins]=0&request[fields][support_url]=0&request[fields][support_threads]=0&request[fields][support_threads_resolved]=0&request[fields][added]=0&request[fields][homepage]=0&request[fields][upgrade_notice]=0&request[fields][business_model]=0&request[fields][repository_url]=0&request[fields][commercial_support_url]=0&request[fields][donate_link]=0`
    );

    const data = await res.json();

    return data;
};

const FeaturedPlugin = async ({ attributes }) => {
    const data = await getData(attributes?.productSlug);

    return <PluginInfo pluginInfo={data} attributes={attributes} />;
};

export default FeaturedPlugin;
