"use client";

// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
// import { sanitize } from "@litonarefin/utils/miscellaneous";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import getTypographyCSS from "@litonarefin/utils/getTypographyCSS";
import { Fragment } from "react";

const IconText = ({ attributes }) => {
    const {
        text,
        textColor = "#475467",
        textTypo = {
            textFont: { font: "", weight: 700 },
            textSize: "16px",
            textLineHeight: "20px",
        },
        gap = "8",
        margin,
        icon,
        alignment = "center",
    } = attributes;
    // const allAOS = getAOSAnimation(attributes);

    const marginCss = getDimensionCSS(margin, "margin");
    const { typography: textTypography } = getTypographyCSS(textTypo);

    return (
        <Fragment>
            <div
                // {...allAOS}
                className="jlt-icon-text__wrapper"
                {...(attributes?.anchor && { id: attributes?.anchor })}>
                {icon ? (
                    <span
                        className="jlt-icon-text__icon"
                        dangerouslySetInnerHTML={{ __html: icon }}
                    />
                ) : null}

                <span
                    className="jlt-icon-text__text"
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </div>
            <style jsx>{`
                .jlt-icon-text__wrapper {
                    ${getMinifyCSS(`
                        display: flex;
                        align-items: ${alignment};
                        ${marginCss ? marginCss : ""}
                        > .jlt-icon-text__icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        > .jlt-icon-text__text {
                            margin: 0;
                            ${textColor ? `color: ${textColor};` : ""}
                            ${gap ? `margin-left: ${gap}px;` : ""}
                            ${textTypography ? textTypography : ""}
                        }
                    `)}
                }
            `}</style>
        </Fragment>
    );
};

export default IconText;
