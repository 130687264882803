"use client";

import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import { getCleanSpacing } from "@litonarefin/utils/spacing";
import { Fragment } from "react";
import { LAPTOP, TABLET, MOBILE } from "@litonarefin/utils/breakpoint";
import { getColor } from "@litonarefin/utils/color";

const Column = ({ attributes, children }) => {
    return (
        <Fragment>
            <div {...(attributes?.anchor && { id: attributes?.anchor })} className={`column ${attributes?.className ? attributes?.className : ""}`}>
                {children}
            </div>

            {/* 
                ${attributes?.jltBlockSpacing?.desktop
                    ? `display: flex; flex-direction: column; gap: ${attributes?.jltBlockSpacing?.desktop}px;`
                : ""} 
            */}

            <style jsx>{`
                .column {
                    position: relative;

                    ${attributes?.jltDisplay && attributes?.jltDisplay !== "unset" ? `display: ${attributes?.jltDisplay};` : ""}
                    ${attributes?.jltFlxDir?.desktop && attributes?.jltFlxDir?.desktop !== "unset" && attributes?.jltFlxDir?.desktop !== "null"
                        ? `flex-direction: ${attributes?.jltFlxDir?.desktop};`
                        : ""}
                            ${attributes?.jltAlignItems?.desktop && attributes?.jltAlignItems?.desktop !== "unset" ? `align-items: ${attributes?.jltAlignItems?.desktop};` : ""}
                            ${attributes?.jltJustifyContent?.desktop && attributes?.jltJustifyContent?.desktop !== "unset" ? `justify-content: ${attributes?.jltJustifyContent?.desktop};` : ""}
                            ${attributes?.jltColumns?.desktop && attributes?.jltDisplay === "grid" ? `grid-template-columns: repeat(${attributes?.jltColumns?.desktop}, 1fr);` : ""}
                            ${attributes?.jltBlockSpacing?.desktop ? `gap: ${attributes?.jltBlockSpacing?.desktop}px;` : ""}

                    /* ${attributes?.style?.color?.background ? `background: ${attributes?.style?.color?.background};` : ""} */
                    ${attributes?.style?.color?.background || attributes?.style?.color?.gradient || getColor(attributes?.backgroundColor)
                        ? `background: ${attributes?.style?.color?.background || attributes?.style?.color?.gradient || getColor(attributes?.backgroundColor)};`
                        : ""}
                    ${getCleanSpacing(attributes?.style?.spacing?.blockGap) ? `display: flex; flex-direction: column; gap: ${getCleanSpacing(attributes?.style?.spacing?.blockGap)};` : ""}

                    ${getDimensionCSS(attributes?.jltMargin?.desktop, "margin") ? getDimensionCSS(attributes?.jltMargin?.desktop, "margin", true) : ""}
                    ${getDimensionCSS(attributes?.jltPadding?.desktop, "padding") ? getDimensionCSS(attributes?.jltPadding?.desktop, "padding") : ""}

                    ${attributes?.jltWidth?.desktop && attributes?.jltWidth?.desktop !== "undefined" ? `flex-basis: ${attributes?.jltWidth?.desktop};` : ""}

                    ${attributes?.jltOrder?.desktop ? `order: ${attributes?.jltOrder?.desktop};` : ""}

                    ${attributes?.layout?.justifyContent
                        ? `display: flex; flex-direction: column; align-items: ${
                              attributes?.layout?.justifyContent === "right" ? "end" : attributes?.layout?.justifyContent === "left" ? "start" : attributes?.layout?.justifyContent
                          };`
                        : ""}

                    ${attributes?.verticalAlignment === "top" ? "align-self: self-start;" : ""}
                    ${attributes?.verticalAlignment === "center" ? "align-self: center;" : ""}
                    ${attributes?.verticalAlignment === "bottom" ? "align-self: self-end;" : ""}

                    & > * {
                        ${attributes?.layout?.contentSize ? `width: ${attributes?.layout?.contentSize}; max-width: ${attributes?.layout?.contentSize};` : ""}
                    }
                    ${attributes?.style?.border?.color && attributes?.style?.border?.style && attributes?.style?.border?.width
                        ? `border: ${attributes?.style?.border?.width} ${attributes?.style?.border?.style} ${attributes?.style?.border?.color};`
                        : ""}

                    ${attributes?.jltBorderRadius ? `border-radius: ${attributes?.jltBorderRadius}px;` : ""}
                }

                //laptop device
                @media only screen and (max-width: ${LAPTOP}px) {
                    .column {
                        ${attributes?.jltFlxDir?.laptop && attributes?.jltFlxDir?.laptop !== "unset" && attributes?.jltFlxDir?.laptop !== "null"
                            ? `flex-direction: ${attributes?.jltFlxDir?.laptop};`
                            : ""}
                        ${attributes?.jltAlignItems?.laptop && attributes?.jltAlignItems?.laptop !== "unset" ? `align-items: ${attributes?.jltAlignItems?.laptop};` : ""}
                            ${attributes?.jltJustifyContent?.laptop && attributes?.jltJustifyContent?.laptop !== "unset" ? `justify-content: ${attributes?.jltJustifyContent?.laptop};` : ""}
                            ${attributes?.jltColumns?.laptop && attributes?.jltDisplay === "grid" ? `grid-template-columns: repeat(${attributes?.jltColumns?.laptop}, 1fr);` : ""}
                            ${attributes?.jltBlockSpacing?.laptop ? `gap: ${attributes?.jltBlockSpacing?.laptop}px;` : ""}
                        ${attributes?.jltBlockSpacing?.laptop ? `gap: ${attributes?.jltBlockSpacing?.laptop}px;` : ""}
                        ${getDimensionCSS(attributes?.jltMargin?.laptop, "margin") ? getDimensionCSS(attributes?.jltMargin?.laptop, "margin", true) : ""}
                            ${getDimensionCSS(attributes?.jltPadding?.laptop, "padding") ? getDimensionCSS(attributes?.jltPadding?.laptop, "padding") : ""}

                            ${attributes?.jltWidth?.laptop && attributes?.jltWidth?.laptop !== "undefined" ? `flex-basis: ${attributes?.jltWidth?.laptop};` : ""}
                            ${attributes?.jltOrder?.laptop ? `order: ${attributes?.jltOrder?.laptop};` : ""}
                    }
                }

                //tablet device
                @media only screen and (max-width: ${TABLET}px) {
                    .column {
                        ${attributes?.jltFlxDir?.tablet && attributes?.jltFlxDir?.tablet !== "unset" && attributes?.jltFlxDir?.tablet !== "null"
                            ? `flex-direction: ${attributes?.jltFlxDir?.tablet};`
                            : ""}
                        ${attributes?.jltAlignItems?.tablet && attributes?.jltAlignItems?.tablet !== "unset" ? `align-items: ${attributes?.jltAlignItems?.tablet};` : ""}
                            ${attributes?.jltJustifyContent?.tablet && attributes?.jltJustifyContent?.tablet !== "unset" ? `justify-content: ${attributes?.jltJustifyContent?.tablet};` : ""}
                            ${attributes?.jltColumns?.tablet && attributes?.jltDisplay === "grid" ? `grid-template-columns: repeat(${attributes?.jltColumns?.tablet}, 1fr);` : ""}
                            ${attributes?.jltBlockSpacing?.tablet ? `gap: ${attributes?.jltBlockSpacing?.tablet}px;` : ""}
                        ${attributes?.jltBlockSpacing?.tablet ? `gap: ${attributes?.jltBlockSpacing?.tablet}px;` : ""}
                        ${getDimensionCSS(attributes?.jltMargin?.tablet, "margin") ? getDimensionCSS(attributes?.jltMargin?.tablet, "margin", true) : ""}
                            ${getDimensionCSS(attributes?.jltPadding?.tablet, "padding") ? getDimensionCSS(attributes?.jltPadding?.tablet, "padding") : ""}

                            ${attributes?.jltWidth?.tablet && attributes?.jltWidth?.tablet !== "undefined" ? `flex-basis: ${attributes?.jltWidth?.tablet};` : ""}
                            ${attributes?.jltOrder?.tablet ? `order: ${attributes?.jltOrder?.tablet};` : ""}
                    }
                }

                // mobile device
                @media only screen and (max-width: ${MOBILE}px) {
                    .column {
                        ${attributes?.jltFlxDir?.mobile && attributes?.jltFlxDir?.mobile !== "unset" && attributes?.jltFlxDir?.mobile !== "null"
                            ? `flex-direction: ${attributes?.jltFlxDir?.mobile};`
                            : ""}
                        ${attributes?.jltAlignItems?.mobile && attributes?.jltAlignItems?.mobile !== "unset" ? `align-items: ${attributes?.jltAlignItems?.mobile};` : ""}
                            ${attributes?.jltJustifyContent?.mobile && attributes?.jltJustifyContent?.mobile !== "unset" ? `justify-content: ${attributes?.jltJustifyContent?.mobile};` : ""}
                            ${attributes?.jltColumns?.mobile && attributes?.jltDisplay === "grid" ? `grid-template-columns: repeat(${attributes?.jltColumns?.mobile}, 1fr);` : ""}
                            ${attributes?.jltBlockSpacing?.mobile ? `gap: ${attributes?.jltBlockSpacing?.mobile}px;` : ""}
                        ${attributes?.jltBlockSpacing?.mobile ? `gap: ${attributes?.jltBlockSpacing?.mobile}px;` : ""}
                        ${getDimensionCSS(attributes?.jltMargin?.mobile, "margin") ? getDimensionCSS(attributes?.jltMargin?.mobile, "margin", true) : ""}
                            ${getDimensionCSS(attributes?.jltPadding?.mobile, "padding") ? getDimensionCSS(attributes?.jltPadding?.mobile, "padding") : ""}
                            ${attributes?.jltWidth?.mobile && attributes?.jltWidth?.mobile !== "undefined" ? `flex-basis: ${attributes?.jltWidth?.mobile};` : ""}
                            ${attributes?.jltOrder?.mobile ? `order: ${attributes?.jltOrder?.mobile};` : ""}
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Column;
