export const getCleanSpacing = (space) => {
    if (space?.length <= 7) {
        const pattern = /[0-9pxremvhw%]/g;
        const result = space.match(pattern);
        let finalResult = "";
        result?.forEach((char) => {
            finalResult += char;
        });
        return finalResult;
    }
    const getSpace = {
        30: "32px",
        40: "48px",
        50: "72px",
        60: "112px",
        70: "128px",
        80: "176px",
    };
    if (space?.length > 8) {
        const num = space.substring(space?.length - 2, space?.length);
        return `${getSpace[num]}`;
    }
};

export const getDimentionStyles = (attr, type) => {
    let dimentionStyles = "";

    if (attr?.openBox === "all-border") {
        dimentionStyles += `${type}: ${attr?.top};`;
    }
    if (attr?.openBox === "single-border") {
        dimentionStyles += `${type}-top: ${attr?.top}; ${type}-right: ${attr?.right}; ${type}-bottom: ${attr?.bottom}; ${type}-left: ${attr?.left};`;
    }

    return { dimentionStyles };
};
