export default function getNumberUnit(num) {
    // Billions
    return Math.abs(Number(num)) >= 1.0e9
        ? removeExtraZero(Math.abs(Number(num))) / 1.0e9 + "B"
        : // Millions
        Math.abs(Number(num)) >= 1.0e6
        ? removeExtraZero(Math.abs(Number(num))) / 1.0e6 + "M"
        : // Thousands
        Math.abs(Number(num)) >= 1.0e3
        ? removeExtraZero(Math.abs(Number(num)) / 1.0e3) + "K"
        : Math.abs(Number(num));
}

function removeExtraZero(val) {
    let number = val.toFixed(1);
    return number.replace(/\.0+$/, "");
}
