"use client";

import { getColor } from "@litonarefin/utils/color";
import { getCleanSpacing } from "@litonarefin/utils/spacing";
import { Fragment } from "react";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import { LAPTOP, TABLET, MOBILE } from "@litonarefin/utils/breakpoint";

const Paragraph = ({ attributes }) => {
    const { style } = attributes;

    return (
        <Fragment>
            <p
                {...(attributes?.anchor && { id: attributes?.anchor })}
                className={`text${
                    attributes.className ? ` ${attributes.className}` : ""
                }`}
                dangerouslySetInnerHTML={{
                    // __html: sanitize(attributes?.content),
                    __html: attributes?.content,
                }}
            />

            <style jsx>{`
                .text {
                    ${style?.color?.text || getColor(attributes?.textColor)
                        ? `color: ${
                              style?.color?.text ||
                              getColor(attributes?.textColor)
                          };`
                        : ""} ${attributes?.jltTextAlign?.desktop ||
                    attributes?.align
                        ? `text-align: ${
                              attributes?.jltTextAlign?.desktop ||
                              attributes?.align
                          };`
                        : ""}${(attributes?.jltFontSize?.desktop &&
                        attributes?.jltFontSize?.desktop !== "undefined") ||
                    style?.typography?.fontSize
                        ? `font-size: ${
                              attributes?.jltFontSize?.desktop ||
                              style?.typography?.fontSize
                          };`
                        : ""}${style?.typography?.fontWeight
                        ? `font-weight: ${style?.typography?.fontWeight};`
                        : ""}${(attributes?.jltLineHeight?.desktop &&
                        attributes?.jltLineHeight?.desktop !== "undefined") ||
                    style?.typography?.lineHeight
                        ? `line-height: ${
                              attributes?.jltLineHeight?.desktop ||
                              style?.typography?.lineHeight
                          };`
                        : ""}${style?.typography?.textTransform
                        ? `text-transform: ${style?.typography?.textTransform};`
                        : ""}${style?.typography?.letterSpacing
                        ? `letter-spacing: ${style?.typography?.letterSpacing};`
                        : ""}${getCleanSpacing(style?.spacing?.margin?.top)
                        ? `margin-top: ${getCleanSpacing(
                              style?.spacing?.margin?.top
                          )};`
                        : ""} ${getCleanSpacing(style?.spacing?.margin?.bottom)
                        ? `margin-bottom: ${getCleanSpacing(
                              style?.spacing?.margin?.bottom
                          )};`
                        : ""} ${getCleanSpacing(style?.spacing?.margin?.left)
                        ? `margin-left: ${getCleanSpacing(
                              style?.spacing?.margin?.left
                          )};`
                        : ""} ${getCleanSpacing(style?.spacing?.margin?.right)
                        ? `margin-right: ${getCleanSpacing(
                              style?.spacing?.margin?.right
                          )};`
                        : ""} ${getCleanSpacing(style?.spacing?.padding?.top)
                        ? `padding-top: ${getCleanSpacing(
                              style?.spacing?.padding?.top
                          )};`
                        : ""} ${getCleanSpacing(style?.spacing?.padding?.bottom)
                        ? `padding-bottom: ${getCleanSpacing(
                              style?.spacing?.padding?.bottom
                          )};`
                        : ""} ${getCleanSpacing(style?.spacing?.padding?.left)
                        ? `padding-left: ${getCleanSpacing(
                              style?.spacing?.padding?.left
                          )};`
                        : ""} ${getCleanSpacing(style?.spacing?.padding?.right)
                        ? `padding-right: ${getCleanSpacing(
                              style?.spacing?.padding?.right
                          )};`
                        : ""} ${getDimensionCSS(
                        attributes?.jltMargin?.desktop,
                        "margin"
                    )
                        ? getDimensionCSS(
                              attributes?.jltMargin?.desktop,
                              "margin"
                          )
                        : ""} ${getDimensionCSS(
                        attributes?.jltPadding?.desktop,
                        "padding"
                    )
                        ? getDimensionCSS(
                              attributes?.jltPadding?.desktop,
                              "padding"
                          )
                        : ""}
                        > a {
                        color: var(--primary);
                    }
                }

                //laptop device
                @media only screen and (max-width: ${LAPTOP}px) {
                    .text {
                        ${attributes?.jltFontSize?.laptop &&
                        attributes?.jltFontSize?.laptop !== "undefined"
                            ? `font-size: ${attributes?.jltFontSize?.laptop};`
                            : ""}
                        ${attributes?.jltLineHeight?.laptop
                            ? `line-height: ${attributes?.jltLineHeight?.laptop};`
                            : ""}
                            ${attributes?.jltTextAlign?.laptop
                            ? `text-align: ${attributes?.jltTextAlign?.laptop};`
                            : ""}
                            ${getDimensionCSS(
                            attributes?.jltMargin?.laptop,
                            "margin"
                        )
                            ? getDimensionCSS(
                                  attributes?.jltMargin?.laptop,
                                  "margin"
                              )
                            : ""}
                            ${getDimensionCSS(
                            attributes?.jltPadding?.laptop,
                            "padding"
                        )
                            ? getDimensionCSS(
                                  attributes?.jltPadding?.laptop,
                                  "padding"
                              )
                            : ""}
                    }
                }

                //tablet device
                @media only screen and (max-width: ${TABLET}px) {
                    .text {
                        ${attributes?.jltFontSize?.tablet
                            ? `font-size: ${attributes?.jltFontSize?.tablet};`
                            : ""}
                        ${attributes?.jltLineHeight?.tablet
                            ? `line-height: ${attributes?.jltLineHeight?.tablet};`
                            : ""}
                            ${attributes?.jltTextAlign?.tablet
                            ? `text-align: ${attributes?.jltTextAlign?.tablet};`
                            : ""}
                            ${getDimensionCSS(
                            attributes?.jltMargin?.tablet,
                            "margin"
                        )
                            ? getDimensionCSS(
                                  attributes?.jltMargin?.tablet,
                                  "margin"
                              )
                            : ""}
                            ${getDimensionCSS(
                            attributes?.jltPadding?.tablet,
                            "padding"
                        )
                            ? getDimensionCSS(
                                  attributes?.jltPadding?.tablet,
                                  "padding"
                              )
                            : ""}
                    }
                }

                // mobile device
                @media only screen and (max-width: ${MOBILE}px) {
                    .text {
                        ${attributes?.jltFontSize?.mobile &&
                        attributes?.jltFontSize?.mobile !== "undefined"
                            ? `font-size: ${attributes?.jltFontSize?.mobile};`
                            : ""}
                        ${attributes?.jltLineHeight?.mobile
                            ? `line-height: ${attributes?.jltLineHeight?.mobile};`
                            : ""}
                            ${attributes?.jltTextAlign?.mobile
                            ? `text-align: ${attributes?.jltTextAlign?.mobile};`
                            : ""}
                            ${getDimensionCSS(
                            attributes?.jltMargin?.mobile,
                            "margin"
                        )
                            ? getDimensionCSS(
                                  attributes?.jltMargin?.mobile,
                                  "margin"
                              )
                            : ""}
                            ${getDimensionCSS(
                            attributes?.jltPadding?.mobile,
                            "padding"
                        )
                            ? getDimensionCSS(
                                  attributes?.jltPadding?.mobile,
                                  "padding"
                              )
                            : ""}
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Paragraph;
