import React from "react";

const Container = ({ children, cols }) => {
    return (
        <div className="jlt-flex jlt-gap-3" style={{ gridTemplateColumns: `repeat(${cols}, 1fr)` }}>
            {children}
        </div>
    );
};

export default Container;
