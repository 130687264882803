import { REVALIDATE } from "@litonarefin/utils/index";
import GlossaryList from "@litonarefin/components/GlossaryList";

const getData = async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL}/wp-json/wp/v2/wpadminify_posts/?_fields=id,title,slug&per_page=100&page=1`, {
        headers: {
            "Content-Type": "application/json",
        },
        next: {
            revalidate: REVALIDATE,
        },
    });

    const firstData = await res.json();

    if (firstData?.length === 5) {
        const res = await fetch(`${process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL}/wp-json/wp/v2/wpadminify_posts/?_fields=id,title,slug&per_page=100&page=2`, {
            headers: {
                "Content-Type": "application/json",
            },
            next: {
                revalidate: REVALIDATE,
            },
        });

        const seconddata = await res.json();

        return [...firstData, ...seconddata];
    }

    return firstData;
};

const GlossaryPosts = async () => {
    const data = await getData();

    const posts = data.reduce((acc, curr) => {
        const category = curr.title.rendered.charAt(0);

        if (acc.hasOwnProperty(category)) {
            Object.assign(acc, { ...acc, [category]: [...acc[category], curr] });
        } else if (!isNaN(category) && acc.hasOwnProperty("Others")) {
            Object.assign(acc, { ...acc, Others: [...acc.Others, curr] });
        } else if (!isNaN(category)) {
            Object.assign(acc, { Others: [curr] });
        } else {
            Object.assign(acc, { [category]: [curr] });
        }

        return acc;
    }, {});

    return <GlossaryList posts={posts} />;
};

export default GlossaryPosts;
