"use client";

import { useState } from "react";
import Modal from "@litonarefin/components/Modal";
import axios from "axios";
import { handleToastr } from "@litonarefin/utils/handleToastr";
import { getIcon } from "@litonarefin/utils/icons";

function FreeDownload({
    productId,
    productName,
    listId = "",
    tagId = "",
    btnText = "Free Download",
}) {
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState("");

    const handleFreeDownload = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let name = formData.get("name");
        let email = formData.get("email");

        try {
            const URL = `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/download-product`;
            const dataObj = {
                form_id: process.env.NEXT_PUBLIC_DOWNLOAD_FORM_ID,
                data: {
                    email,
                    name,
                    product_title: productName || "",
                    product_id: productId || "",
                    list_id: listId,
                    tag_id: tagId,
                },
            };

            const { data } = await axios.post(URL, dataObj);

            if (!!data?.data?.error) {
                setError(data?.data?.error);
            }

            if (!!data?.data?.insertId) {
                handleToastr("Please check your email inbox to Download the Product");
                setOpenModal(false);
                setError("");
            } else {
                handleToastr("Something went worng. Please try again", "warning");
            }
        } catch (e) {
            console.warn(e);
        }
    };

    const insertModalContent = () => {
        return (
            <div className="jlt-p-6 jlt-w-[350px] jlt-h-full jlt-bg-white jlt-rounded-lg">
                <form onSubmit={handleFreeDownload}>
                    <div>
                        <label
                            htmlFor="name"
                            className="jlt-text-base jlt-text-[#16213E] jlt-font-bold">
                            Name
                        </label>
                        <input
                            type="text"
                            className="jlt-w-full jlt-h-[28px] jlt-mt-2 jlt-p-4 jlt-bg-[#FCFDFF] jlt-rounded-[4px] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-outline-none"
                            id="name"
                            autoComplete="off"
                            name="name"
                        />
                    </div>
                    <div className="jlt-mt-5">
                        <label
                            htmlFor="email"
                            className="jlt-text-base jlt-text-[#16213E] jlt-font-bold">
                            Email Address
                        </label>
                        <input
                            type="email"
                            className="jlt-w-full jlt-h-[28px] jlt-mt-2 jlt-p-4 jlt-bg-[#FCFDFF] jlt-rounded-[4px] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-outline-none"
                            id="email"
                            autoComplete="off"
                            name="email"
                            required
                        />
                        {error ? (
                            <span className="jlt-text-xs jlt-text-red-700">{error}</span>
                        ) : null}
                    </div>
                    <p className="jlt-text-sm jlt-my-3">
                        An email will be sent to the provided address to complete your download.
                    </p>
                    <button
                        type="submit"
                        className="jlt-py-3 jlt-px-6 jlt-w-full jlt-bg-[#256EFF] jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-[20px] jlt-text-white jlt-text-sm jlt-font-bold">
                        Download
                    </button>
                </form>
            </div>
        );
    };

    return (
        <>
            <button
                // onClick={handleFreeDownload}
                onClick={() => {
                    setOpenModal(true);
                    setError("");
                }}
                className="jlt-bg-[#FB346D] jlt-py-[10px] jlt-px-6 jlt-text-white jlt-text-base jlt-font-bold jlt-rounded-full jlt-border jlt-border-[#FB346D] jlt-inline-flex jlt-items-center jlt-gap-2">
                <span>{getIcon("download")}</span>
                {btnText || "Free"}
            </button>
            {!!openModal ? (
                <Modal onCloseModal={() => setOpenModal(false)}>{insertModalContent()}</Modal>
            ) : null}
        </>
    );
}

export default FreeDownload;
