export const getTextAlign = (textAlign) => {
    const textAlignMap = {
        left: "jlt-text-left",
        center: "jlt-text-center",
        right: "jlt-text-right",
    };

    return `${textAlignMap[textAlign]}` || "";
};

export const getFontSizeForHeading = (level) => {
    const fontSizeMap = {
        1: "jlt-text-6xl",
        2: "jlt-text-5xl",
        3: "jlt-text-4xl",
        4: "jlt-text-3xl",
        5: "jlt-text-2xl",
        6: "jlt-text-xl",
    };

    return `${fontSizeMap[level]}` || "";
};

//only use for typography
function objToString(obj) {
    let str = "";
    let validObj = {};
    Object.keys(obj).map((k) => {
        if (obj[k] === undefined) {
            return;
        }
        if (obj[k] === "undefined" || !obj[k]) {
            return;
        }
        if (obj[k] !== "") {
            validObj[k] = obj[k];
        }
    });

    for (const [p, val] of Object.entries(validObj)) {
        str += `${p}:${val};`;
    }
    return str;
}

//typography
export const getTypographyStyles = (typo) => {
    const typoObj = {
        "font-family": typo?.textFont?.font,
        "font-weight": typo?.textFont?.weight,
        "font-size": typo?.textSize,
        "text-transform": typo?.textTransform,
        "font-style": typo?.textStyle,
        "text-decoration": typo?.textDecoration,
        "letter-spacing": typo?.textLetterSpacing,
        "line-height": typo?.textLineHeight,
        "word-Spacing": typo?.textWordSpacing,
    };

    let typography = objToString(typoObj);

    return { typography };
};

//text shadow
export const getTextShadowStyles = (attr) => {
    const isTextShadow = Object.keys(attr).length === 0;
    let textShadow = "";
    isTextShadow
        ? (textShadow += "")
        : (textShadow += `text-shadow: ${attr?.shadowHorizontal}px ${attr?.shadowVertical}px ${attr?.shadowBlur}px ${attr?.shadowColor};`);

    return { textShadow };
};
