"use client";

import { Fragment } from "react";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";

const Buttons = ({ attributes, children }) => {
    return (
        <Fragment>
            <div {...(attributes?.anchor && { id: attributes?.anchor })} className="btns">
                {children}
            </div>
            <style jsx>{`
                .btns {
                    ${getMinifyCSS(`
                        ${attributes?.layout?.type ? `display: ${attributes?.layout?.type};` : ""}
                        ${
                            attributes?.layout?.justifyContent
                                ? `justify-content: ${attributes?.layout?.justifyContent};`
                                : ""
                        }
                    `)}
                }
            `}</style>
        </Fragment>
    );
};

export default Buttons;
