const CountrySelect = ({ field }) => {
    return (
        <div className="jlt-w-full jlt-py-2">
            <label htmlFor={field.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                {field.settings.label}
            </label>
            <select
                type={field.attributes.type}
                id={field.attributes.name}
                name={field.attributes.name}
                className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                {Object.values(field.options).map((option, i) => (
                    <option key={i}>{option}</option>
                ))}
            </select>
        </div>
    );
};

export default CountrySelect;
