import Form from "./Form";

const getFormFields = async (form_id) => {
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_REST_API}/wpadminify_get_fluent_form_fields`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ form_id }),
        }
    );
    const data = await res.json();

    return data;
};

const FluentForm = async ({ attributes }) => {
    const formData = await getFormFields(attributes?.formId);

    return (
        <div>
            <Form
                formId={attributes?.formId}
                formData={formData?.data?.data?.form_fields}
            />
        </div>
    );
};

export default FluentForm;
