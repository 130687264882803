"use client";

import { getBorderCSS } from "@litonarefin/utils/getBorderCSS";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import Link from "next/link";
import { LAPTOP, TABLET, MOBILE } from "@litonarefin/utils/breakpoint";

function Button({ attributes }) {
    return (
        <>
            <Link href={`${attributes?.url?.url || "#"}`} id="link" legacyBehavior>
                <a
                    {...(attributes?.url?.blank && { target: "_blank" })}
                    {...(attributes?.url?.rel && { rel: "nofollow noopener" })}
                    className="jlt_b_btn">
                    <span>{attributes?.text || "Learn More"}</span>
                    {attributes?.isIcon ? (
                        <svg
                            width="18"
                            height="12"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L14.5858 8H1C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H14.5858L10.2929 1.70711C9.90237 1.31658 9.90237 0.683417 10.2929 0.292893Z"
                                fill="black"
                            />
                        </svg>
                    ) : null}
                </a>
            </Link>
            <style jsx>{`
                .jlt_b_btn {
                    color: ${attributes?.color || "#fff"};
                    background: ${attributes?.bgColor || "#0d49ce"};
                    font-size: ${attributes?.typography?.desktop?.fontSize || "14"}px;
                    font-weight: ${attributes?.typography?.desktop?.fontWeight || 600};
                    ${getBorderCSS(attributes?.border) ? getBorderCSS(attributes?.border) : ""}
                    border-radius: ${attributes?.borderRadius || 40}px;
                    ${getDimensionCSS(
                        attributes?.padding?.desktop || {
                            top: "12px",
                            right: "20px",
                            bottom: "12px",
                            left: "20px",
                        },
                        "padding"
                    )}
                    ${attributes?.isIcon ? `display: flex; align-items: center; gap: 8px;` : ""}
                    transition: all ${attributes?.transition}ms;
                    > svg {
                        path {
                            ${attributes?.color ? `fill: ${attributes?.color};` : "fill: #fff;"}
                        }
                    }
                    &:hover {
                        ${attributes?.hoverColor ? `color: ${attributes?.hoverColor};` : ""};
                        ${attributes?.hoverBgColor
                            ? `background: ${attributes?.hoverBgColor};`
                            : ""};
                        ${getBorderCSS(attributes?.hoverBorder)
                            ? getBorderCSS(attributes?.hoverBorder)
                            : ""}
                        > svg {
                            path {
                                ${attributes?.hoverColor ? `fill: ${attributes?.hoverColor};` : ""}
                            }
                        }
                    }
                }

                //laptop device
                @media only screen and (max-width: ${LAPTOP}px) {
                    .jlt_b_btn {
                        ${attributes?.typography?.laptop?.fontSize
                            ? `font-size: ${attributes?.typography?.laptop?.fontSize}px;`
                            : ""}
                        ${attributes?.typography?.laptop?.fontWeight
                            ? `font-weight: ${attributes?.typography?.laptop?.fontWeight};`
                            : ""};
                        ${getDimensionCSS(attributes?.padding?.laptop, "padding")
                            ? getDimensionCSS(attributes?.padding?.laptop, "padding")
                            : ""}
                    }
                }

                //tablet device
                @media only screen and (max-width: ${TABLET}px) {
                    .jlt_b_btn {
                        ${attributes?.typography?.tablet?.fontSize
                            ? `font-size: ${attributes?.typography?.tablet?.fontSize}px;`
                            : ""}
                        ${attributes?.typography?.tablet?.fontWeight
                            ? `font-weight: ${attributes?.typography?.tablet?.fontWeight};`
                            : ""};
                        ${getDimensionCSS(attributes?.padding?.tablet, "padding")
                            ? getDimensionCSS(attributes?.padding?.tablet, "padding")
                            : ""}
                    }
                }

                // mobile device
                @media only screen and (max-width: ${MOBILE}px) {
                    .jlt_b_btn {
                        ${attributes?.typography?.mobile?.fontSize
                            ? `font-size: ${attributes?.typography?.mobile?.fontSize}px;`
                            : ""}
                        ${attributes?.typography?.mobile?.fontWeight
                            ? `font-weight: ${attributes?.typography?.mobile?.fontWeight};`
                            : ""};
                        ${getDimensionCSS(attributes?.padding?.mobile, "padding")
                            ? getDimensionCSS(attributes?.padding?.mobile, "padding")
                            : ""}
                    }
                }
            `}</style>
        </>
    );
}

export default Button;
