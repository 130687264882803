import Text from "./Text";
import CountrySelect from "./CountrySelect";
import Radio from "./Radio";
import Checkbox from "./Checkbox";
import Textarea from "./Textarea";
import Number from "./Number";
import Select from "./Select";
import Names from "./Names";
import Address from "./Address";
import Container from "./Container";
import Button from "./Button";
import NetPromoterScore from "./NetPromoterScore";
import Date from "./Date";

const FormRenderer = ({ fields }) => {
    return fields.map((field, i) => {
        switch (field.element) {
            case "container": {
                return (
                    <Container key={i} cols={field?.columns?.length}>
                        {field?.columns?.map((col, index) => (
                            <div key={index} style={{ flexBasis: `${col?.width}%` }}>
                                <FormRenderer fields={col?.fields} />
                            </div>
                        ))}
                    </Container>
                );
            }
            case "custom_submit_button": {
                return <Button key={i} data={field} />;
            }
            case "input_name": {
                if (field?.fields) {
                    return <Names key={i} fields={field?.fields} name={field.attributes.name} />;
                }
            }
            case "input_text": {
                return <Text key={i} field={field} />;
            }
            case "input_number": {
                return <Number key={i} field={field} />;
            }
            case "input_email": {
                return <Text key={i} field={field} />;
            }
            case "input_url": {
                return <Text key={i} field={field} />;
            }
            case "address": {
                if (field?.fields) {
                    return <Address key={i} fields={field?.fields} name={field.attributes.name} />;
                }
            }
            case "select_country": {
                return <CountrySelect key={i} field={field} />;
            }
            case "input_radio": {
                return <Radio key={i} field={field} />;
            }
            case "input_checkbox": {
                return <Checkbox key={i} field={field} />;
            }
            case "textarea": {
                return <Textarea key={i} field={field} />;
            }
            case "select": {
                return <Select key={i} field={field} />;
            }
            case "net_promoter_score": {
                return <NetPromoterScore key={i} field={field} />;
            }
            case "input_date": {
                return <Date key={i} field={field} />;
            }
            default: {
                return null;
            }
        }
    });
};

export default FormRenderer;
