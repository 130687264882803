"use client";

import { useState } from "react";
import NewPricingTab from "./NewPricingTab";
import OneYear from "./page/OneYear";
import TwoYear from "./page/TwoYear";
import Lifetime from "./page/Lifetime";

const NewPricing = () => {
    const [activePricingTab, setActivePricingTab] = useState(1);

    return (
        <section className="jlt-pb-32 jlt-pt-[128px] md:jlt-pt-[178px] lg:jlt-pt-[204px]">
            <div className="jt-container jlt-mx-auto">
                <div className="jlt-pricing">
                    <NewPricingTab
                        activePricingTab={activePricingTab}
                        setActivePricingTab={setActivePricingTab}
                        pricingTabs={[
                            { id: 1, title: "1 Year", description: "Updates & Support" },
                            { id: 2, title: "2 Year", description: "Updates & Support" },
                            { id: 3, title: "Lifetime", description: "Updates & Support" },
                        ]}
                    />
                    {activePricingTab === 1 ? <OneYear /> : null}
                    {activePricingTab === 2 ? <TwoYear /> : null}
                    {activePricingTab === 3 ? <Lifetime /> : null}
                </div>
            </div>
        </section>
    );
};

export default NewPricing;
