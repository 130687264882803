"use client";

import { getColor } from "@litonarefin/utils/color";
import { Fragment } from "react";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import { getCleanSpacing } from "@litonarefin/utils/spacing";
import { LAPTOP, TABLET, MOBILE } from "@litonarefin/utils/breakpoint";

const Columns = ({ attributes, colNumbers, children }) => {
    const { style, className, jltDisplay, jltAlignItems, jltJustifyContent, jltFlxDir, jltColumns, jltMargin, jltPadding, jltBlockSpacing } = attributes;

    return (
        <Fragment>
            <div {...(attributes?.anchor && { id: attributes?.anchor })} className={`row ${className ? className : ""}`}>
                {children}
            </div>

            <style jsx>{`
                .row {
                    ${jltDisplay ? `display: ${jltDisplay};` : ""}
                    ${jltFlxDir?.desktop ? `flex-direction: ${jltFlxDir?.desktop};` : ""}
                        ${jltAlignItems?.desktop ? `align-items: ${jltAlignItems?.desktop};` : ""}
                        ${jltJustifyContent?.desktop ? `justify-content: ${jltJustifyContent?.desktop};` : ""}
                        ${(+jltColumns?.desktop || colNumbers) > 1 && jltDisplay !== "flex" ? `display: grid; grid-template-columns: repeat(${+jltColumns?.desktop || colNumbers}, 1fr);` : ""}
                        ${style?.border?.width ? `border-width: ${style?.border?.width};` : ""};
                    ${style?.border?.color || getColor(attributes?.borderColor) ? `border-color: ${style?.border?.color || getColor(attributes?.borderColor)};` : ""}
                    ${style?.border?.style ? `border-style: ${style?.border?.style};` : ""}
                    ${style?.border?.radius ? `border-radius: ${style?.border?.radius};` : ""}
                        ${style?.border?.left?.width && style?.border?.left?.color
                        ? `border-left: ${style?.border?.left?.width} ${style?.border?.left?.style || "solid"} ${style?.border?.left?.color};`
                        : ""}

                        ${style?.border?.right?.width && style?.border?.right?.color
                        ? `border-right: ${style?.border?.right?.width} ${style?.border?.right?.style || "solid"} ${style?.border?.right?.color};`
                        : ""}
                        ${style?.border?.top?.width && style?.border?.top?.color
                        ? `border-top: ${style?.border?.top?.width} ${style?.border?.top?.style || "solid"} ${style?.border?.top?.color};`
                        : ""}
                        ${style?.border?.bottom?.width && style?.border?.bottom?.color
                        ? `border-bottom: ${style?.border?.bottom?.width} ${style?.border?.bottom?.style || "solid"} ${style?.border?.bottom?.color};`
                        : ""}
                        ${style?.color?.background || style?.color?.gradient || getColor(attributes?.backgroundColor)
                        ? `background: ${style?.color?.background || style?.color?.gradient || getColor(attributes?.backgroundColor)};`
                        : ""}

                        ${getCleanSpacing(style?.spacing?.margin?.top) ? `margin-top: ${getCleanSpacing(style?.spacing?.margin?.top)}!important;` : ""}
                        ${getCleanSpacing(style?.spacing?.margin?.bottom) ? `margin-bottom: ${getCleanSpacing(style?.spacing?.margin?.bottom)};` : ""}
                        ${getCleanSpacing(style?.spacing?.padding?.top) ? `padding-top: ${getCleanSpacing(style?.spacing?.padding?.top)};` : ""}
                        
                        ${getCleanSpacing(style?.spacing?.padding?.bottom) ? `padding-bottom: ${getCleanSpacing(style?.spacing?.padding?.bottom)};` : ""}
                        ${getCleanSpacing(style?.spacing?.padding?.left) ? `padding-left: ${getCleanSpacing(style?.spacing?.padding?.left)};` : ""}
                        ${getCleanSpacing(style?.spacing?.padding?.right) ? `padding-right: ${getCleanSpacing(style?.spacing?.padding?.right)};` : ""}
                        ${getCleanSpacing(style?.spacing?.blockGap?.top) ? `gap: ${getCleanSpacing(style?.spacing?.blockGap?.top)}` : ""}

                        ${getDimensionCSS(jltMargin?.desktop, "margin") ? getDimensionCSS(jltMargin?.desktop, "margin") : ""}
                        ${getDimensionCSS(jltPadding?.desktop, "padding") ? getDimensionCSS(jltPadding?.desktop, "padding") : ""}
                        ${jltBlockSpacing?.desktop ? `gap: ${jltBlockSpacing?.desktop}px;` : ""}
                }

                //laptop device
                @media only screen and (max-width: ${LAPTOP}px) {
                    .row {
                        ${jltFlxDir?.laptop ? `flex-direction: ${jltFlxDir?.laptop};` : ""}
                        ${jltAlignItems?.laptop ? `align-items: ${jltAlignItems?.laptop};` : ""}
                            ${jltJustifyContent?.laptop ? `justify-content: ${jltJustifyContent?.laptop};` : ""}
                            ${+jltColumns?.laptop ? `grid-template-columns: repeat(${+jltColumns?.laptop}, 1fr);` : ""}
                            ${getDimensionCSS(jltMargin?.laptop, "margin") ? getDimensionCSS(jltMargin?.laptop, "margin") : ""}
                            ${getDimensionCSS(jltPadding?.laptop, "padding") ? getDimensionCSS(jltPadding?.laptop, "padding") : ""}
                            ${jltBlockSpacing?.laptop ? `gap: ${jltBlockSpacing?.laptop}px;` : ""}
                    }
                }

                //tablet device
                @media only screen and (max-width: ${TABLET}px) {
                    .row {
                        ${jltFlxDir?.tablet ? `flex-direction: ${jltFlxDir?.tablet};` : ""}
                        ${jltAlignItems?.tablet ? `align-items: ${jltAlignItems?.tablet};` : ""}
                            ${jltJustifyContent?.tablet ? `justify-content: ${jltJustifyContent?.tablet};` : ""}
                            ${+jltColumns?.tablet ? `grid-template-columns: repeat(${+jltColumns?.tablet}, 1fr);` : ""}
                            ${getDimensionCSS(jltMargin?.tablet, "margin") ? getDimensionCSS(jltMargin?.tablet, "margin") : ""}
                            ${getDimensionCSS(jltPadding?.tablet, "padding") ? getDimensionCSS(jltPadding?.tablet, "padding") : ""}
                            ${jltBlockSpacing?.tablet ? `gap: ${jltBlockSpacing?.tablet}px;` : ""}
                    }
                }

                // mobile device
                @media only screen and (max-width: ${MOBILE}px) {
                    .row {
                        ${jltFlxDir?.mobile ? `flex-direction: ${jltFlxDir?.mobile};` : ""}
                        ${jltAlignItems?.mobile ? `align-items: ${jltAlignItems?.mobile};` : ""}
                            ${jltJustifyContent?.mobile ? `justify-content: ${jltJustifyContent?.mobile};` : ""}
                            ${+jltColumns?.mobile ? `grid-template-columns: repeat(${+jltColumns?.mobile}, 1fr);` : ""}
                            ${getDimensionCSS(jltMargin?.mobile, "margin") ? getDimensionCSS(jltMargin?.mobile, "margin") : ""}
                            ${getDimensionCSS(jltPadding?.mobile, "padding") ? getDimensionCSS(jltPadding?.mobile, "padding") : ""}
                            ${jltBlockSpacing?.mobile ? `gap: ${jltBlockSpacing?.mobile}px;` : ""}
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Columns;
