export const ratingCount = (count) => {
    if (count > 5 || count < 0) {
        const fullStar = Math.floor(5);
        const halfStar = 5 % 1 === 0 ? 0 : 1;
        const emptyStar = 5 - (fullStar + halfStar);
        return { fullStar, halfStar, emptyStar };
    }
    const fullStar = Math.floor(count);
    const halfStar = count % 1 === 0 ? 0 : 1;
    const emptyStar = 5 - (fullStar + halfStar);
    return { fullStar, halfStar, emptyStar };
};
