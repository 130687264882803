const List = ({ attributes, originalContent, children }) => {
    const CustomTag = `${originalContent.slice(1, 3)}`;
    return (
        <CustomTag className={attributes?.ordered ? "jlt-order-list" : "jlt-unorder-list"}>
            {children}
        </CustomTag>
    );
};

export default List;
