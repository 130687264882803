/**
 *
 * @param {*} dimension obj
 * @param {*} type // margin or padding
 * @param {*} imp // important
 * @returns
 */
export const getDimensionCSS = (
    dimension = {},
    type = "margin",
    imp = false
) => {
    if (Object.keys(dimension).length > 0) {
        let isSame = false;

        for (let singleProperty in dimension) {
            isSame += dimension[singleProperty] === dimension.top;
        }
        if (isSame === 4) {
            return `${type}: ${dimension.top}${imp ? "!important" : ""};`;
        } else {
            let dimensionResult = "";
            for (let property in dimension) {
                if (dimension[property] !== undefined) {
                    dimensionResult += `${type}-${property}: ${
                        dimension[property]
                    }${imp ? "!important" : ""};`;
                }
            }

            return dimensionResult;
        }
    }
};
