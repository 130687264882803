"use client";

import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import { Fragment } from "react";
import { LAPTOP, TABLET, MOBILE } from "@litonarefin/utils/breakpoint";

function strToNum(str = "") {
    let num = str.toString().replace("px", "");
    return +num;
}

const Image = ({ attributes }) => {
    return (
        <Fragment>
            {attributes?.url ? (
                <>
                    {attributes?.href ? (
                        <a
                            href={attributes?.href}
                            target={
                                attributes?.linkTarget == "_blank"
                                    ? "_blank"
                                    : "_self"
                            }
                            className={`jlt-relative jlt-inline-block ${
                                attributes?.hoverImg?.url ? "jlt-group" : ""
                            }`}>
                            <img
                                {...(attributes?.anchor && {
                                    id: attributes?.anchor,
                                })}
                                src={attributes?.url}
                                alt={attributes?.alt || attributes?.title}
                                width={
                                    !!attributes?.width
                                        ? strToNum(attributes.width) || 400
                                        : "100%"
                                }
                                height={
                                    !!attributes?.height
                                        ? strToNum(attributes.height) || 400
                                        : "100%"
                                }
                                className={`${
                                    attributes?.hoverImg?.url
                                        ? "group-hover:jlt-opacity-0 group-hover:jlt-transition-all jlt-duration-150"
                                        : ""
                                } ${attributes?.className}`}
                            />
                            {attributes?.hoverImg?.url ? (
                                <img
                                    src={attributes?.hoverImg.url}
                                    alt={
                                        attributes?.hoverImg.alt ||
                                        attributes?.title
                                    }
                                    width={
                                        !!attributes?.width
                                            ? strToNum(attributes.width) || 400
                                            : "100%"
                                    }
                                    height={
                                        !!attributes?.height
                                            ? strToNum(attributes.height) || 400
                                            : "100%"
                                    }
                                    className={`jlt-absolute jlt-top-0 jlt-bottom-0 jlt-m-auto jlt-left-0 jlt-right-0 jlt-invisible jlt-opacity-0 group-hover:jlt-opacity-100 group-hover:jlt-visible group-hover:jlt-transition-all jlt-duration-150 ${attributes?.className}`}
                                />
                            ) : null}
                        </a>
                    ) : (
                        <>
                            {attributes?.hoverImg?.url ? (
                                <div className="jlt-relative jlt-group jlt-inline-block">
                                    <img
                                        {...(attributes?.anchor && {
                                            id: attributes?.anchor,
                                        })}
                                        src={attributes?.url}
                                        alt={
                                            attributes?.alt || attributes?.title
                                        }
                                        width={
                                            !!attributes?.width
                                                ? strToNum(attributes.width) ||
                                                  400
                                                : "100%"
                                        }
                                        height={
                                            !!attributes?.height
                                                ? strToNum(attributes.height) ||
                                                  400
                                                : "100%"
                                        }
                                        className={`group-hover:jlt-opacity-0 group-hover:jlt-transition-all jlt-duration-150 ${attributes?.className}`}
                                    />
                                    {attributes?.hoverImg?.url ? (
                                        <img
                                            src={attributes?.hoverImg.url}
                                            alt={
                                                attributes?.hoverImg.alt ||
                                                attributes?.title
                                            }
                                            width={
                                                !!attributes?.width
                                                    ? strToNum(
                                                          attributes.width
                                                      ) || 400
                                                    : "100%"
                                            }
                                            height={
                                                !!attributes?.height
                                                    ? strToNum(
                                                          attributes.height
                                                      ) || 400
                                                    : "100%"
                                            }
                                            className={`jlt-absolute jlt-top-0 jlt-bottom-0 jlt-m-auto jlt-left-0 jlt-right-0 jlt-invisible jlt-opacity-0 group-hover:jlt-opacity-100 group-hover:jlt-visible group-hover:jlt-transition-all jlt-duration-150 ${attributes?.className}`}
                                        />
                                    ) : null}
                                </div>
                            ) : (
                                <img
                                    {...(attributes?.anchor && {
                                        id: attributes?.anchor,
                                    })}
                                    src={attributes?.url}
                                    alt={attributes?.alt || attributes?.title}
                                    width={
                                        !!attributes?.width
                                            ? strToNum(attributes.width) || 400
                                            : "100%"
                                    }
                                    height={
                                        !!attributes?.height
                                            ? strToNum(attributes.height) || 400
                                            : "100%"
                                    }
                                    className={attributes?.className}
                                />
                            )}
                        </>
                    )}
                </>
            ) : null}

            {!!attributes?.caption ? (
                <figcaption>{attributes?.caption}</figcaption>
            ) : null}

            <style jsx>{`
                img {
                    ${getMinifyCSS(`
                        ${
                            !!attributes?.jltScale?.desktop &&
                            attributes?.jltScale?.desktop !== "undefined"
                                ? `scale: ${attributes?.jltScale?.desktop};`
                                : ""
                        }
                        ${
                            !!attributes?.jltTransalateX?.desktop &&
                            attributes?.jltTransalateX?.desktop !==
                                "undefined" &&
                            (!attributes?.jltTransalateY?.desktop ||
                                attributes?.jltTransalateY?.desktop ===
                                    "undefined")
                                ? `transform: translateX(${attributes?.jltTransalateX?.desktop});`
                                : ""
                        }
                        ${
                            (!attributes?.jltTransalateX?.desktop ||
                                attributes?.jltTransalateX?.desktop ===
                                    "undefined") &&
                            !!attributes?.jltTransalateY?.desktop &&
                            attributes?.jltTransalateY?.desktop !== "undefined"
                                ? `transform: translateY(${attributes?.jltTransalateY?.desktop});`
                                : ""
                        }
                        ${
                            !!attributes?.jltTransalateX?.desktop &&
                            attributes?.jltTransalateX?.desktop !==
                                "undefined" &&
                            !!attributes?.jltTransalateY?.desktop &&
                            attributes?.jltTransalateY?.desktop !== "undefined"
                                ? `transform: translateX(${attributes?.jltTransalateX?.desktop}) translateY(${attributes?.jltTransalateY?.desktop});`
                                : ""
                        }
                        ${attributes?.align === "center" ? "margin: auto;" : ""}
                        ${
                            attributes?.align === "right"
                                ? "margin: 0 0 0 auto;"
                                : ""
                        }
                    `)}
                }

                //laptop device
                @media only screen and (max-width: ${LAPTOP}px) {
                    img {
                        ${getMinifyCSS(`
                            ${
                                !!attributes?.jltScale?.laptop &&
                                attributes?.jltScale?.laptop !== "undefined"
                                    ? `scale: ${attributes?.jltScale?.laptop};`
                                    : ""
                            }
                            ${
                                !!attributes?.jltTransalateX?.laptop &&
                                attributes?.jltTransalateX?.laptop !==
                                    "undefined" &&
                                (!attributes?.jltTransalateY?.laptop ||
                                    attributes?.jltTransalateY?.laptop ===
                                        "undefined")
                                    ? `transform: translateX(${attributes?.jltTransalateX?.laptop});`
                                    : ""
                            }
                            ${
                                (!attributes?.jltTransalateX?.laptop ||
                                    attributes?.jltTransalateX?.laptop ===
                                        "undefined") &&
                                !!attributes?.jltTransalateY?.laptop &&
                                attributes?.jltTransalateY?.laptop !==
                                    "undefined"
                                    ? `transform: translateY(${attributes?.jltTransalateY?.laptop});`
                                    : ""
                            }
                            ${
                                !!attributes?.jltTransalateX?.laptop &&
                                attributes?.jltTransalateX?.laptop !==
                                    "undefined" &&
                                !!attributes?.jltTransalateY?.laptop &&
                                attributes?.jltTransalateY?.laptop !==
                                    "undefined"
                                    ? `transform: translateX(${attributes?.jltTransalateX?.laptop}) translateY(${attributes?.jltTransalateY?.laptop});`
                                    : ""
                            }

                        `)}
                    }
                }

                //tablet device
                @media only screen and (max-width: ${TABLET}px) {
                    img {
                        ${getMinifyCSS(`
                            ${
                                !!attributes?.jltScale?.tablet &&
                                attributes?.jltScale?.tablet !== "undefined"
                                    ? `scale: ${attributes?.jltScale?.tablet};`
                                    : ""
                            }
                            ${
                                !!attributes?.jltTransalateX?.tablet &&
                                attributes?.jltTransalateX?.tablet !==
                                    "undefined" &&
                                (!attributes?.jltTransalateY?.tablet ||
                                    attributes?.jltTransalateY?.tablet ===
                                        "undefined")
                                    ? `transform: translateX(${attributes?.jltTransalateX?.tablet});`
                                    : ""
                            }
                            ${
                                (!attributes?.jltTransalateX?.tablet ||
                                    attributes?.jltTransalateX?.tablet ===
                                        "undefined") &&
                                !!attributes?.jltTransalateY?.tablet &&
                                attributes?.jltTransalateY?.tablet !==
                                    "undefined"
                                    ? `transform: translateY(${attributes?.jltTransalateY?.tablet});`
                                    : ""
                            }
                            ${
                                !!attributes?.jltTransalateX?.tablet &&
                                attributes?.jltTransalateX?.tablet !==
                                    "undefined" &&
                                !!attributes?.jltTransalateY?.tablet &&
                                attributes?.jltTransalateY?.tablet !==
                                    "undefined"
                                    ? `transform: translateX(${attributes?.jltTransalateX?.tablet}) translateY(${attributes?.jltTransalateY?.tablet});`
                                    : ""
                            }

                        `)}
                    }
                }

                // mobile device
                @media only screen and (max-width: ${MOBILE}px) {
                    img {
                        ${getMinifyCSS(`
                            ${
                                !!attributes?.jltScale?.mobile &&
                                attributes?.jltScale?.mobile !== "undefined"
                                    ? `scale: ${attributes?.jltScale?.mobile};`
                                    : ""
                            }
                            ${
                                !!attributes?.jltTransalateX?.mobile &&
                                attributes?.jltTransalateX?.mobile !==
                                    "undefined" &&
                                (!attributes?.jltTransalateY?.mobile ||
                                    attributes?.jltTransalateY?.mobile ===
                                        "undefined")
                                    ? `transform: translateX(${attributes?.jltTransalateX?.mobile});`
                                    : ""
                            }
                            ${
                                (!attributes?.jltTransalateX?.mobile ||
                                    attributes?.jltTransalateX?.mobile ===
                                        "undefined") &&
                                !!attributes?.jltTransalateY?.mobile &&
                                attributes?.jltTransalateY?.mobile !==
                                    "undefined"
                                    ? `transform: translateY(${attributes?.jltTransalateY?.mobile});`
                                    : ""
                            }
                            ${
                                !!attributes?.jltTransalateX?.mobile &&
                                attributes?.jltTransalateX?.mobile !==
                                    "undefined" &&
                                !!attributes?.jltTransalateY?.mobile &&
                                attributes?.jltTransalateY?.mobile !==
                                    "undefined"
                                    ? `transform: translateX(${attributes?.jltTransalateX?.mobile}) translateY(${attributes?.jltTransalateY?.mobile});`
                                    : ""
                            }
                        `)}
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Image;
