import Link from "next/link";

const getPrice = (obj) => {
    if (obj === "Free") {
        return "Free";
    }

    const prices = [];
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            const price = obj[key];
            prices.push(Number(price));
        }
    }
    if (prices.length === 1 && prices[0] === 0) {
        return `Free`;
    }
    return `$${Math.min(...prices)} - $${Math.max(...prices)}`;
};

function FilterTabProductCard({ product }) {
    return (
        <div className="jlt-rounded-lg jlt-pt-6 jlt-px-6 jlt-pb-8 jlt-bg-white jlt-border jlt-border-[#d9d9d9] jlt-border-solid">
            <Link href={product?.slug ? `/${product?.slug}` : "#"}>
                <img
                    alt={product?.thumbnail?.alt || "Product Image"}
                    src={product?.thumbnail?.url}
                    width={336}
                    height={228}
                    className="jlt-h-56 jlt-object-cover"
                />
            </Link>

            <div className="jlt-mt-6">
                <h3 className="jlt-flex jlt-justify-between jlt-text-[#1d2939] jlt-text-xl jlt-leading-8 jlt-font-bold">
                    <Link href={product?.slug ? `/${product?.slug}` : "#"}>
                        <span className="hover:hover:jlt-text-[#FB346D] jlt-transition jlt-duration-200">
                            {product?.title}
                        </span>
                    </Link>
                    <span>{getPrice(product?.download)}</span>
                </h3>

                <div
                    className="jlt-mt-4 jlt-text-base jlt-text-[#667085]"
                    dangerouslySetInnerHTML={{ __html: product?.short_description }}
                />
            </div>
            <div className="jlt-flex jlt-items-center jlt-justify-between jlt-mt-8">
                <Link
                    href={product?.slug ? `/${product?.slug}` : "#"}
                    type="button"
                    className="jlt-flex jlt-items-center jlt-gap-x-2 jlt-py-2 jlt-px-4 jlt-rounded jlt-text-sm jlt-font-bold jlt-text-[#0641b8] jlt-bg-[#f5f8ff]">
                    Download
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.75 11.25V12.15C15.75 13.4101 15.75 14.0402 15.5048 14.5215C15.289 14.9448 14.9448 15.289 14.5215 15.5048C14.0402 15.75 13.4101 15.75 12.15 15.75H5.85C4.58988 15.75 3.95982 15.75 3.47852 15.5048C3.05516 15.289 2.71095 14.9448 2.49524 14.5215C2.25 14.0402 2.25 13.4101 2.25 12.15V11.25M12.75 7.5L9 11.25M9 11.25L5.25 7.5M9 11.25V2.25"
                            stroke="#0641B8"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </Link>
                <a
                    href={product?.demo || "#"}
                    target="_blank"
                    className="jlt-flex jlt-items-center jlt-gap-x-2 jlt-py-2 jlt-px-4 jlt-rounded jlt-text-sm jlt-font-bold jlt-text-[#ed8e36] jlt-bg-[#fffaf6]">
                    Preview
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.81509 9.53488C1.71295 9.37315 1.66188 9.29229 1.63329 9.16756C1.61182 9.07387 1.61182 8.92613 1.63329 8.83244C1.66188 8.70771 1.71295 8.62685 1.81509 8.46512C2.65915 7.12863 5.17155 3.75 9.0003 3.75C12.8291 3.75 15.3415 7.12863 16.1855 8.46512C16.2877 8.62685 16.3387 8.70771 16.3673 8.83244C16.3888 8.92613 16.3888 9.07387 16.3673 9.16756C16.3387 9.29229 16.2877 9.37315 16.1855 9.53488C15.3415 10.8714 12.8291 14.25 9.0003 14.25C5.17155 14.25 2.65915 10.8714 1.81509 9.53488Z"
                            stroke="#ED8E36"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M9.0003 11.25C10.2429 11.25 11.2503 10.2426 11.2503 9C11.2503 7.75736 10.2429 6.75 9.0003 6.75C7.75766 6.75 6.7503 7.75736 6.7503 9C6.7503 10.2426 7.75766 11.25 9.0003 11.25Z"
                            stroke="#ED8E36"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </a>
                <div className="jlt-flex jlt-items-center jlt-gap-[2px] jlt-text-sm jlt-font-medium jlt-text-[#475467]">
                    <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3 11.1817C2.09551 10.5762 1.5 9.54516 1.5 8.375C1.5 6.61732 2.84363 5.17347 4.55981 5.01453C4.91086 2.8791 6.76518 1.25 9 1.25C11.2348 1.25 13.0891 2.8791 13.4402 5.01453C15.1564 5.17347 16.5 6.61732 16.5 8.375C16.5 9.54516 15.9045 10.5762 15 11.1817M6 11.75L9 14.75M9 14.75L12 11.75M9 14.75V8"
                            stroke="#667085"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    {product?.details?.download_count}+
                </div>
            </div>
        </div>
    );
}

export default FilterTabProductCard;
