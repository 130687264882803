//box shadow
export const getBoxShadowCSS = (attr = {}) => {
    const isBoxShadow = Object.keys(attr).length === 0;
    let boxShadow = "";
    isBoxShadow
        ? ""
        : (boxShadow += `box-shadow: ${attr?.shadowHorizontal}px ${attr?.shadowVertical}px ${attr?.shadowBlur}px ${attr?.shadowSpread}px ${attr?.shadowColor};`);

    return { boxShadow };
};
