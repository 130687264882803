"use client";

import { handleToastr } from "@litonarefin/utils/handleToastr";
import axios from "axios";
import { useRef, useState } from "react";
import Loader from "@litonarefin/components/Loader";

function JltFreemiusAffiliateForm() {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);

    async function onSubmit(form) {
        const formData = {
            first_name: form.get("first_name"),
            last_name: form.get("last_name"),
            email: form.get("email"),
            website: form.get("website"),
            payout_email: form.get("payout_email"),
            stats_description: form.get("stats_description"),
        };

        const { data } = await axios.post(
            `/api/affiliate-application`,
            formData
        );
        setIsLoading(false);

        if (data?.success) {
            formRef.current.reset();
            handleToastr(data?.message);
        }

        if (!data?.success) {
            handleToastr(data?.output, "error");
        }
    }
    return (
        <div className="jlt-border jlt-mt-4 jlt-p-12 jlt-max-w-[900px] jlt-bg-white">
            <form action={onSubmit} ref={formRef}>
                <div className="jlt-grid jlt-grid-cols-2 jlt-gap-4">
                    <div>
                        <label
                            htmlFor="first_name"
                            className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                            First Name
                        </label>
                        <input
                            required
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="First Name"
                            // onChange={handleOnChange}
                            className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                            // value={formInfo.names.first_name}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="last_name"
                            className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                            Last Name
                        </label>
                        <input
                            required
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            // onChange={handleOnChange}
                            className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                            // value={formInfo.names.first_name}
                        />
                    </div>
                </div>
                <div className="jlt-mt-6">
                    <label
                        htmlFor="email"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        Email Address
                    </label>
                    <input
                        required
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        // onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        // value={formInfo.names.first_name}
                    />
                </div>
                <div className="jlt-mt-6">
                    <label
                        htmlFor="website"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        Website URL
                    </label>
                    <input
                        required
                        type="text"
                        id="website"
                        name="website"
                        placeholder="Website URL (Without http or https)"
                        // onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        // value={formInfo.names.first_name}
                    />
                </div>
                <div className="jlt-mt-6">
                    <label
                        htmlFor="payout_email"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        Paypal Email
                    </label>
                    <input
                        required
                        type="text"
                        id="payout_email"
                        name="payout_email"
                        placeholder="Paypal Email"
                        // onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        // value={formInfo.names.first_name}
                    />
                </div>
                <div className="jlt-mt-6">
                    <label
                        htmlFor="stats_description"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        How will you promote us?
                    </label>
                    <textarea
                        required
                        id="stats_description"
                        name="stats_description"
                        placeholder={`How do you Promote WP Adminfiy?
                            
100k monthly Pageviews
I manage a FB group of 50,000 members.
My Youtube Channel has 100k+ Subscribers`}
                        // onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[150px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-py-2 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        // value={formInfo.names.first_name}
                    />
                </div>

                <button
                    onClick={() => {
                        setIsLoading(true);
                    }}
                    type="submit"
                    className={`jlt-py-3 jlt-px-6 jlt-font-semibold jlt-border-2 jlt-border-l-0 jlt-bg-[#0d49ce] jlt-text-white jlt-border-[#0d49ce] jlt-mt-6 jlt-rounded jlt-uppercase jlt-flex jlt-items-center jlt-gap-2`}>
                    {isLoading ? <Loader type="button-loader" /> : null}
                    Become an Affiliate
                </button>
            </form>
        </div>
    );
}

export default JltFreemiusAffiliateForm;
