import React, { useEffect, useState } from "react";
import RequredStar from "./RequredStar";

const Address = ({ fields, name }) => {
    const [address, setAddress] = useState({ address_line_1: "", address_line_2: "", city: "", state: "", zip: "", country: "" });

    useEffect(() => {
        setAddress({
            address_line_1: fields.address_line_1.attributes.value,
            address_line_2: fields.address_line_2.attributes.value,
            city: fields.city.attributes.value,
            state: fields.state.attributes.value,
            zip: fields.zip.attributes.value,
            country: fields.country.attributes.value,
        });
    }, []);

    return (
        <div className="jlt-grid jlt-grid-cols-2 jlt-items-center jlt-gap-3">
            <div className="jlt-w-full jlt-py-2">
                <label htmlFor={fields.address_line_1.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    {fields.address_line_1.settings.label} <RequredStar field={fields.address_line_1} />
                </label>
                <input
                    required={fields.address_line_1.settings.validation_rules?.required?.value}
                    type={fields.address_line_1.attributes.type}
                    id={fields.address_line_1.attributes.name}
                    // name={fields.address_line_1.attributes.name}
                    placeholder={fields.address_line_1.attributes.placeholder}
                    value={address.address_line_1}
                    onChange={(e) => {
                        setAddress({ ...address, address_line_1: e.target.value });
                    }}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
            </div>

            <div className="jlt-w-full jlt-py-2">
                <label htmlFor={fields.address_line_2.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    {fields.address_line_2.settings.label} <RequredStar field={fields.address_line_2} />
                </label>
                <input
                    required={fields.address_line_2.settings.validation_rules?.required?.value}
                    type={fields.address_line_2.attributes.type}
                    id={fields.address_line_2.attributes.name}
                    // name={fields.address_line_2.attributes.name}
                    placeholder={fields.address_line_2.attributes.placeholder}
                    value={address.address_line_2}
                    onChange={(e) => {
                        setAddress({ ...address, address_line_2: e.target.value });
                    }}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
            </div>

            <div className="jlt-w-full jlt-py-2">
                <label htmlFor={fields.city.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    {fields.city.settings.label} <RequredStar field={fields.city} />
                </label>
                <input
                    required={fields.city.settings.validation_rules?.required?.value}
                    type={fields.city.attributes.type}
                    id={fields.city.attributes.name}
                    // name={fields.city.attributes.name}
                    placeholder={fields.city.attributes.placeholder}
                    value={address.city}
                    onChange={(e) => {
                        setAddress({ ...address, city: e.target.value });
                    }}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
            </div>

            <div className="jlt-w-full jlt-py-2">
                <label htmlFor={fields.state.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    {fields.state.settings.label} <RequredStar field={fields.state} />
                </label>
                <input
                    required={fields.state.settings.validation_rules?.required?.value}
                    type={fields.state.attributes.type}
                    id={fields.state.attributes.name}
                    // name={fields.state.attributes.name}
                    placeholder={fields.state.attributes.placeholder}
                    value={address.state}
                    onChange={(e) => {
                        setAddress({ ...address, state: e.target.value });
                    }}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
            </div>

            <div className="jlt-w-full jlt-py-2">
                <label htmlFor={fields.zip.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    {fields.zip.settings.label} <RequredStar field={fields.zip} />
                </label>
                <input
                    required={fields.zip.settings.validation_rules?.required?.value}
                    type={fields.zip.attributes.type}
                    id={fields.zip.attributes.name}
                    // name={fields.zip.attributes.name}
                    placeholder={fields.zip.attributes.placeholder}
                    value={address.zip}
                    onChange={(e) => {
                        setAddress({ ...address, zip: e.target.value });
                    }}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
            </div>

            <div className="jlt-w-full jlt-py-2">
                <label htmlFor={fields.country.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    {fields.country.settings.label} <RequredStar field={fields.country} />
                </label>
                <select
                    type={fields.country.attributes.type}
                    id={fields.country.attributes.name}
                    // name={fields.country.attributes.name}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                    value={address.country}
                    onChange={(e) => {
                        setAddress({ ...address, country: e.target.value });
                    }}>
                    <option>--Select--</option>
                    {Object.values(fields.country.options).map((option, i) => (
                        <option key={i}>{option}</option>
                    ))}
                </select>

                <input className="jlt-hidden" name={name} defaultValue={getFullAddress(address)}></input>
            </div>
        </div>
    );
};

export default Address;

function getFullAddress(address) {
    let fullAddress = "";

    if (address.address_line_1) {
        fullAddress = address.address_line_1 + ",";
    }
    if (address.address_line_2) {
        fullAddress += " " + address.address_line_2 + ",";
    }
    if (address.city) {
        fullAddress += " " + address.city + ",";
    }
    if (address.state) {
        fullAddress += " " + address.state + ",";
    }
    if (address.zip) {
        fullAddress += " " + address.zip + ",";
    }
    if (address.country) {
        fullAddress += " " + address.country;
    }

    return fullAddress;
}
