"use client";

import { useEffect, useState } from "react";

function Youtube({ attributes }) {
    const [height, setHeight] = useState(350);

    const embedCodeArr = attributes?.url?.split("v=");
    const embedCode = embedCodeArr.pop();

    useEffect(() => {
        const youtubeFrame = document.getElementById("jlt-youtube-video");
        const offsetWidth = youtubeFrame.offsetWidth;

        let calculateHeight = (offsetWidth) => {
            let aspectRatio = 16 / 9;
            let height = parseFloat((offsetWidth / aspectRatio).toFixed(2));
            return height;
        };

        setHeight(calculateHeight(offsetWidth));
    }, []);

    return (
        <iframe
            id="jlt-youtube-video"
            width={"100%"}
            height={height || 350}
            src={`https://www.youtube.com/embed/${embedCode}`}
        />
    );
}

export default Youtube;
