import { Subscribe } from "@litonarefin/components/Subscribe";

const SubscribeForm = ({ attributes }) => {
    return (
        <Subscribe
            isSimple={true}
            type={attributes?.formType}
            listID={attributes?.listId}
            tagID={attributes?.tagId}
        />
    );
};

export default SubscribeForm;
