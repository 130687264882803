import Image from "next/image";

const Cover = ({ image, children }) => {
    return (
        <div
            {...(attributes?.anchor && { id: attributes?.anchor })}
            className="jlt-flex jlt-justify-center lg:jlt-justify-end jlt-text-center jlt-text-white jlt-mt-[100px] lg:jlt-mt-0">
            <Image
                alt="Cover"
                src={image}
                width={400}
                height={350}
                className="lt-object-cover md:jlt-h-[369px] md:jlt-w-[460px"
                sizes="100vw"
            />
            {children}
        </div>
    );
};

export default Cover;
