import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import { getIcon } from "@litonarefin/utils/icons";
import StyledIcon from "../StyledIcon";

const ComparisonTable = ({ attributes }) => {
    const {
        header = [
            {
                id: 1,
                title: "Personal",
            },
            {
                id: 2,
                title: "Business",
            },
            {
                id: 3,
                title: "Agency",
            },
            {
                id: 4,
                title: "Enterprize",
            },
        ],
        content = [
            {
                text: "Support",
                tooltip: "This is support tooltip text",
                1: "Ticket Based",
                2: "Ticket Based",
                3: "Ticket Based",
                4: "Ticket Based",
            },
            {
                text: "Duration",
                tooltip: "This is duration tooltip text",
                1: "3 Month",
                2: "6 Month",
                3: "9 Month",
                4: "1 Year",
            },
            {
                text: "Updates",
                tooltip: "This is Updates tooltip text",
                1: "N/A",
                2: "ok",
                3: "N/A",
                4: "ok",
            },
        ],
    } = attributes;

    function getCols() {
        let cols = "2fr";
        header?.forEach((head) => {
            cols += " 1fr";
        });

        return cols;
    }

    return (
        <div className="jlt-bg-white jlt-border jlt-p-8 jlt-border-[#F2F4F7] jlt-overflow-x-auto">
            <table className="jlt-w-full">
                <thead>
                    <tr className="jlt-grid" style={{ gridTemplateColumns: getCols() }}>
                        <td></td>
                        {header.map((head) => (
                            <td className="jlt-text-base jlt-font-bold jlt-text-[#182230]" key={head.id}>
                                {head.title}
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody className="jlt-mt-10 jlt-block">
                    {content.map((item, i) => (
                        <tr key={i} className="jlt-grid odd:jlt-bg-gray-50 even:jlt-bg-white" style={{ gridTemplateColumns: getCols() }}>
                            <td className="jlt-text-sm jlt-font-semibold jlt-text-[#475467] jlt-py-3 jlt-px-4 jlt-flex jlt-gap-2 jlt-items-center">
                                {item.text}{" "}
                                {item?.tooltip ? (
                                    <Tooltip text={item.tooltip || ""}>
                                        <StyledIcon color="#98A2B3" type="stroke" styles={{ width: "13px", height: "13px" }}>
                                            {getIcon("question")}
                                        </StyledIcon>
                                    </Tooltip>
                                ) : null}
                            </td>
                            {header.map((head) => (
                                <td className="jlt-text-sm jlt-font-medium jlt-text-[#475467] jlt-py-3 jlt-px-4" key={head.id}>
                                    {item?.[head.id] === "N/A" ? <StyledIcon color="#F04438">{getIcon("close")}</StyledIcon> : item?.[head.id] === "ok" ? getIcon("check") : item?.[head.id]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ComparisonTable;
