// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import Link from "next/link";
import { getIcon } from "@litonarefin/utils/icons";

const Product = ({ attributes }) => {
    const {
        className,
        productStyle,
        imageURL,
        imageAlt,
        logoURL,
        logoAlt,
        productName,
        productDescription,
        allProductBtnText,
        isButton,
        btnURL,
        titleClass,
    } = attributes;

    // const allAOS = getAOSAnimation(attributes);

    return (
        <div
            {...(attributes?.anchor && { id: attributes?.anchor })}
            // {...allAOS}
            className={`jlt-flex jlt-flex-col jlt-gap-y-8${className ? ` ${className}` : ""}`}>
            <div className="jlt-rounded-[8px] jlt-bg-white">
                {productStyle !== "style2" && imageURL && (
                    <div className="jlt-relative">
                        <img src={imageURL} alt={imageAlt} width={384} height={216} />
                        <button className="jlt-absolute jlt-top-0 jlt-bottom-0 jlt-left-0 jlt-right-0 jlt-m-auto jlt-w-16 jlt-h-16 jlt-flex jlt-items-center jlt-justify-center jlt-border jlt-border-none jlt-rounded-full jlt-bg-[#256eff] jlt-outline-6 jlt-outline jlt-outline-[rgba(0,0,0,0.05)]">
                            <svg
                                className="jlt-ml-[6px]"
                                width="24"
                                height="28"
                                viewBox="0 0 24 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M24 14L-1.30507e-06 27.8564L-9.36995e-08 0.143593L24 14Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    </div>
                )}

                <div className="jlt-px-8 jlt-py-12 jlt-shadow-lg">
                    <div className="jlt-flex jlt-items-center">
                        {logoURL && <img src={logoURL} alt={logoAlt} width={64} height={64} />}
                        <h3
                            className={`jlt-ml-4 jlt-text-2xl ${
                                titleClass ? ` ${titleClass}` : ""
                            }`}>
                            {productName}
                        </h3>
                    </div>
                    {productDescription && <p className="jlt-mt-8">{productDescription}</p>}

                    {isButton && (
                        <Link
                            href={btnURL?.url || "/#"}
                            className="jlt-mt-4 jlt-flex jlt-gap-2 jlt-items-center jlt-text-sm jlt-text-[#256EFF] jlt-font-semibold">
                            Learn More
                            {getIcon("arrowRight")}
                        </Link>
                    )}
                </div>
            </div>
            {productStyle === "style2" && (
                <div className="jlt-shadow-lg jlt-dshadow-[0px 0px 45px rgba(173,173,188,0.15) 100%] jlt-rounded-[8px] jlt-bg-gradient-to-r jlt-from-[#256EFF] jlt-to-[rgba(133,37,255,0.78)100%] jlt-h-[170px]">
                    <a
                        href=""
                        className="jlt-flex jlt-flex-col jlt-justify-center jlt-items-center jlt-h-full jlt-text-lg jlt-text-[#FFFFFF] jlt-font-bold">
                        {allProductBtnText}
                        <span className="jlt-mt-4">{getIcon("arrowRight")}</span>
                    </a>
                </div>
            )}
        </div>
    );
};

export default Product;
