import React from "react";
import RequredStar from "./RequredStar";

const Text = ({ field }) => {
    if (field?.settings?.visible === false) return;

    return (
        <div className="jlt-w-full jlt-py-2">
            <label htmlFor={field.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                {field.settings.label} <RequredStar field={field} />
            </label>
            <input
                required={field.settings.validation_rules?.required?.value}
                type={field.attributes.type}
                id={field.attributes.name}
                name={field.attributes.name}
                placeholder={field.attributes.placeholder}
                defaultValue={field.attributes.value}
                className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
        </div>
    );
};

export default Text;
