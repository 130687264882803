export const getBorderCSS = (borders = {}) => {
    const borderArray = Object.keys(borders).sort().toString();

    if (borderArray == ["color", "style", "width"].sort().toString()) {
        return `border: ${borders.width} ${borders.style} ${borders.color};`;
    }
    if (borderArray == ["top", "right", "bottom", "left"].sort().toString()) {
        const { top, right, bottom, left } = borders;
        return `border-top: ${top.width} ${top.style} ${top.color}; border-right: ${right.width} ${right.style} ${right.color}; border-bottom: ${bottom.width} ${bottom.style} ${bottom.color}; border-left: ${left.width} ${left.style} ${left.color};`;
    }
};

export const getBorderRadiusCSS = (borderRadius = {}) => {
    if (Object.keys(borderRadius).length > 0) {
        if (
            ((borderRadius.top === borderRadius.right) ===
                borderRadius.bottom) ===
            borderRadius.left
        ) {
            return `border-radius: ${borderRadius.top};`;
        } else {
            return `border-top-left-radius: ${borderRadius.top}; border-top-right-radius: ${borderRadius.right}; border-bottom-right-radius: ${borderRadius.bottom}; border-bottom-left-radius: ${borderRadius.left};`;
        }
    }
};
