"use client";

import React from "react";

const NetPromoterScore = ({ field }) => {
    return (
        <>
            <div className="jlt-py-2">
                <span className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">{field.settings.label}</span>
                <table className="jlt-mt-2">
                    <tbody>
                        <tr className="jlt-border">
                            {field.options.map((option, i) => (
                                <td key={i} className="jlt-border-r last:jlt-border-r-0">
                                    <input className="jlt-hidden" name={field.attributes.name} value={option} type={field.attributes.type} id={field.attributes.name + option} />
                                    <label className="jlt-px-8 jlt-py-2 jlt-block jlt-cursor-pointer" htmlFor={field.attributes.name + option}>
                                        {option}
                                    </label>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                <style jsx>{`
                    table {
                        tbody {
                            tr {
                                td {
                                    input[type="radio"]:checked + label {
                                        background: #0d49ce;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                `}</style>
            </div>
        </>
    );
};

export default NetPromoterScore;
