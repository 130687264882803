import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { getSourceSite } from "./getSourceSite";
const sourceSite = getSourceSite();

// export const REVALIDATE = 3600; // 1 hour
// export const REVALIDATE = 900; // 15 munite
export const REVALIDATE = 5; // 5 second

// Blog Slug Prefix
export const BLOG_PATH_PREFIX = sourceSite === "wpadminify" ? "" : "/blog";

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}
