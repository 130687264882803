"use client";

import { Fragment, useEffect, useState } from "react";
import { getBorderCSS } from "@litonarefin/utils/getBorderCSS";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import axios from "axios";

const PaddleCheckout = ({ attributes }) => {
    const [paddle, setPaddle] = useState();

    const {
        btnText,
        productId,
        productVariation,
        paddleProductId,
        btnColor,
        btnBgColor,
        border,
        borderRadius,
        padding,
        width,
    } = attributes;

    // const allAOS = getAOSAnimation(attributes);

    /**
     * Handle Checkout
     */
    const handleCheckout = async () => {
        paddle?.Checkout.open({
            items: [
                {
                    priceId: paddleProductId,
                    quantity: 1,
                },
            ],
        });
    };

    /**
     * Handle Paymet Action
     * @param {*} event
     * @returns
     */
    const actionHandle = async (event) => {
        if (!event.name) {
            return;
        }

        switch (event.name) {
            case "checkout.loaded":
                break;
            case "checkout.customer.created":
                const existingUserId = await checkExistingUser(event.data.customer.email);

                if (existingUserId?.result?.userId) {
                    sessionStorage.setItem("jlt_uf", existingUserId?.result?.userId);

                    const order = await createOrder(existingUserId?.result?.userId, event.data);
                    if (order?.order_id) {
                        sessionStorage.setItem("jlt_oid", order?.order_id);
                    }
                } else {
                    const user = await createUser(event.data.customer.email);
                    sessionStorage.setItem("jlt_uf", user?.result?.user_id);

                    const order = await createOrder(user?.result?.user_id, event.data);
                    if (order?.order_id) {
                        sessionStorage.setItem("jlt_oid", order?.order_id);
                    }
                }
                break;
            case "checkout.payment.selected":
                break;
            case "checkout.discount.applied":
                // const userId = sessionStorage.getItem("jlt_uf");
                const updateOrder = await updateOrderStatus(
                    "pending",
                    "",
                    event.data.discount.code
                );
                break;
            case "checkout.discount.removed":
                // const removeUpdateOrder = await updateOrderStatus("pending", "", "");
                break;
            case "checkout.items.updated":
                // const userId = sessionStorage.getItem("jlt_uf");

                break;
            case "checkout.payment.initiated":
                break;
            case "checkout.completed":
                await updateOrderStatus("completed", event?.data?.transaction_id, "");
                break;
            default:
                break;
        }
    };

    /**
     * Check Existing User
     * @param {*} email
     */
    const checkExistingUser = async (email) => {
        let { data: checkUserExists } = await axios.get(
            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/users`,
            { params: { email: email } }
        );

        return checkUserExists;
    };

    /**
     * Create User
     * @param {*} email
     * @param {*} password
     */
    const createUser = async (email, password = "jlt_1234") => {
        let { data: registered } = await axios.post(`/api/auth/register`, {
            first_name: "",
            last_name: "",
            user_email: email,
            user_pass: password,
        });

        return registered;
    };

    /**
     * Create Order
     */
    const createOrder = async (userId, data) => {
        const billing = {
            first_name: "",
            last_name: "",
            email: "",
            company: "",
            country: "",
            city: "",
            postCode: "",
            password: "",
        };

        const orderData = {
            user_id: userId,
            order_status: "completed",
            total: data.totals.total,
            billing: billing,
            shipping: billing,
            line_items: [
                {
                    variation_id: productVariation,
                    product_id: productId,
                    quantity: 1,
                },
            ],
            payment_method: "sparkle_paddle_checkout_overlay",
            payment_method_title: "Sparkle Paddle Overlay",
            site_source: process.env.NEXT_PUBLIC_SOURCE_SITE,
        };

        const { data: orderCreateData } = await axios.post(
            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/orders/create`,
            {
                ...orderData,
                // coupon_code: coupon?.code || "",
                pay_link: JSON.stringify({}),
                gutenberg: true,
                transaction_id: data.transaction_id,
            }
        );

        return orderCreateData;
    };

    /**
     * Update Order Status
     * @param {*} order_id
     * @param {*} trans_id
     * @param {*} discount
     */
    const updateOrderStatus = async (status, trans_id = "", coupon_code = "") => {
        const orderId = sessionStorage.getItem("jlt_oid");

        let { data } = await axios.put(
            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/orders/paddle-update`,
            {
                gutenberg: true,
                body: {
                    order_id: orderId,
                    trans_id: trans_id,
                    coupon_code: coupon_code,
                    status: status,
                },
            }
        );

        if (data?.success) {
            // sessionStorage.removeItem("jlt_oid");
        }
    };

    useEffect(() => {
        // Initialization Paddle
        initializePaddle({
            environment: process.env.NEXT_PUBLIC_PADDLE_MODE || "production",
            token: process.env.NEXT_PUBLIC_PADDLE_VENDOR_AUTH_CODE,
            eventCallback: actionHandle,
        }).then((paddleInstance) => {
            if (paddleInstance) {
                setPaddle(paddleInstance);
            }
        });
    }, []);

    return (
        <Fragment>
            <button
                {...(attributes?.anchor && { id: attributes?.anchor })}
                className="jlt-woocommearce-btn jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-[25px] jlt-py-3 jlt-px-6 jlt-font-bold jlt-text-sm jlt-leading-[18px] jlt-text-center jlt-text-white jlt-border-none jlt-cursor-pointer"
                // {...allAOS}
                onClick={handleCheckout}>
                {btnText || "Button Text"}
            </button>

            <style jsx>{`
                .jlt-woocommearce-btn {
                    ${getMinifyCSS(`
                        ${btnBgColor ? `background: ${btnBgColor};` : ""}
                        ${btnColor ? `color: ${btnColor};` : ""}
                        ${borderRadius ? `border-radius: ${borderRadius}px;` : ""}
                        ${width ? `width: ${width};` : ""}
                        ${getBorderCSS(border) ? getBorderCSS(border) : ""}
                        ${
                            getDimensionCSS(padding, "padding")
                                ? getDimensionCSS(padding, "padding")
                                : ""
                        }
                    `)}
                }
            `}</style>
        </Fragment>
    );
};

export default PaddleCheckout;
