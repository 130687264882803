"use client";

import { getBorderCSS } from "@litonarefin/utils/getBorderCSS";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import { Fragment, useState } from "react";
import axios from "axios";
// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import { handleToastr } from "@litonarefin/utils/handleToastr";
import Modal from "@litonarefin/components/Modal";

const SubscribeButton = ({ attributes, icon = null }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const { btnText, listId, tagId, btnColor, btnBgColor, border, borderRadius, padding, width } =
        attributes;

    // const allAOS = getAOSAnimation(attributes);

    const handleDownload = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let name = formData.get("name");
        let email = formData.get("email");

        let customFieldValues = {
            site_source: process.env.NEXT_PUBLIC_NEXTJS_SITE_URL,
        };

        let { data } = await axios.post("/api/subscribe/create", {
            first_name: name,
            email: email,
            lists: listId?.replaceAll(" ", "").split(",").map(Number),
            tags: tagId.replaceAll(" ", "").split(",").map(Number),
            status: "yes",
            custom_values: customFieldValues,
        });
        if (data?.success) {
            handleToastr("Subscribe Success!");
            setIsOpenModal(false);
        }
    };

    const insertModalContent = () => {
        return (
            <div className="jlt-p-6 jlt-w-[350px] jlt-h-full jlt-bg-white jlt-rounded-lg">
                <form onSubmit={handleDownload}>
                    <div>
                        <label
                            htmlFor="name"
                            className="jlt-text-base jlt-text-[#16213E] jlt-font-bold">
                            Name
                        </label>
                        <input
                            type="text"
                            className="jlt-w-full jlt-h-[28px] jlt-mt-2 jlt-p-4 jlt-bg-[#FCFDFF] jlt-rounded-[4px] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-outline-none"
                            id="name"
                            autoComplete="off"
                            name="name"
                        />
                    </div>
                    <div className="jlt-mt-5">
                        <label
                            htmlFor="email"
                            className="jlt-text-base jlt-text-[#16213E] jlt-font-bold">
                            Email Address
                        </label>
                        <input
                            type="email"
                            className="jlt-w-full jlt-h-[28px] jlt-mt-2 jlt-p-4 jlt-bg-[#FCFDFF] jlt-rounded-[4px] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-outline-none"
                            id="email"
                            autoComplete="off"
                            name="email"
                            required
                        />
                    </div>
                    <p className="jlt-text-sm jlt-my-3">
                        An email will be sent to the provided address to complete your download.
                    </p>
                    <button
                        type="submit"
                        className="jlt-py-3 jlt-px-6 jlt-w-full jlt-bg-[#256EFF] jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-[20px] jlt-text-white jlt-text-sm jlt-font-bold">
                        Download
                    </button>
                </form>
            </div>
        );
    };

    return (
        <Fragment>
            <button
                {...(attributes?.anchor && { id: attributes?.anchor })}
                className={`jlt-subscribe-btn jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-[25px] jlt-py-3 jlt-px-6 jlt-font-bold jlt-text-sm jlt-leading-[18px] jlt-text-center jlt-text-white jlt-border-none jlt-cursor-pointer${
                    icon ? ` jlt-flex jlt-items-center jlt-justify-center jlt-gap-2` : ""
                }`}
                onClick={() => setIsOpenModal(true)}>
                <span>{btnText || "Button Text"}</span>
                {icon ? icon : null}
            </button>

            {isOpenModal ? (
                <Modal onCloseModal={() => setIsOpenModal(false)}>{insertModalContent()}</Modal>
            ) : null}

            <style jsx>{`
                .jlt-subscribe-btn {
                    ${getMinifyCSS(`
                        ${btnBgColor ? `background: ${btnBgColor};` : ""}
                        ${btnColor ? `color: ${btnColor};` : ""}
                        ${borderRadius ? `border-radius: ${borderRadius}px;` : ""}
                        ${width ? `width: ${width};` : ""}
                        ${getBorderCSS(border) ? getBorderCSS(border) : ""}
                        ${
                            getDimensionCSS(padding, "padding")
                                ? getDimensionCSS(padding, "padding")
                                : ""
                        }
                    `)}
                }
            `}</style>
        </Fragment>
    );
};

export default SubscribeButton;
