"use client";

import { getTypographyStyles } from "@litonarefin/utils/font";
import { Fragment } from "react";
import { sanitize } from "@litonarefin/utils/miscellaneous";
import CountUp from "react-countup";

const CounterUp = ({ attributes }) => {
    const { typography: numTypography } = getTypographyStyles(
        attributes?.numberTypo || {
            textFont: { font: "", weight: 700 },
            textSize: "32px",
            textLineHeight: "40px",
        }
    );
    const { typography: titleTypography } = getTypographyStyles(
        attributes?.titleTypo || {
            textFont: { font: "", weight: 600 },
            textSize: "18px",
            textLineHeight: "24px",
        }
    );

    return (
        <Fragment>
            <div className="jlt-counter-up">
                <div className="jlt-counter-up-count">
                    <span className="jlt_prefix">{attributes?.beforeText || attributes?.beforeText === "" ? attributes?.beforeText : "$"}</span>
                    <CountUp className="jlt_ending-num" start={0} end={attributes?.endNumber || 1000} duration={1} useEasing={true} separator="," />
                    <span className="jlt_suffix">{attributes?.afterText || attributes?.afterText === "" ? attributes?.afterText : "+"}</span>
                </div>
                <h3
                    className="jlt-counter-up-title jlt-text-base"
                    dangerouslySetInnerHTML={{
                        __html: sanitize(attributes?.title),
                    }}
                />
            </div>
            <style jsx>{`
                .jlt-counter-up {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
                .jlt-counter-up > .jlt-counter-up-count {
                    ${attributes?.numberColor ? `color: ${attributes?.numberColor};` : ""}
                    ${numTypography ? numTypography : ""}
                }
                .jlt-counter-up > .jlt-counter-up-title {
                    ${attributes?.titleColor ? `color: ${attributes?.titleColor};` : ""}
                    ${titleTypography ? titleTypography : ""}
                    ${attributes?.titleGap ? `margin-top: ${attributes?.titleGap}px;` : ""}
                }
            `}</style>
        </Fragment>
    );
};

export default CounterUp;
