import { getIcon } from "@litonarefin/utils/icons";
import StyledIcon from "../StyledIcon";
import Tooltip from "../Tooltip/Tooltip";
import SubscribeButton from "./SubscribeButton";
import Link from "next/link";
import FreeDownload from "@litonarefin/components/FreeDownload/FreeDownload";

function AdvancedPricing({ attributes }) {
    const { price1, price1Btn, price2, price2Btn, priceContent } = attributes;

    return (
        <div id="jlt-pricing">
            <div>
                <div className="jlt-grid jlt-grid-cols-1 md:jlt-grid-cols-2 jlt-gap-4 lg:jlt-gap-0 lg:jlt-grid-cols-[2fr_1fr_1fr]">
                    <div className="jlt-hidden lg:jlt-block"></div>

                    <div className="jlt-border jlt-border-[#d9d9d9] jlt-border-b-0">
                        <div className="jlt-bg-[#f5f5f5] jlt-px-4 jlt-py-8 jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
                            <h3 className="jlt-text-3xl">{price1.title}</h3>
                            <p className="jlt-mt-1 jlt-text-sm">{price1.subtitle}</p>
                        </div>
                        <div className="jlt-px-4 jlt-py-8">
                            <h4 className="jlt-leading-[40px] jlt-text-center jlt-font-bold">
                                <sup className="jlt-text-lg -jlt-top-4">$</sup>
                                <span>{price1.price}</span>
                            </h4>
                            <p className="jlt-text-sm jlt-text-center">{price1.priceDesc}</p>
                        </div>
                    </div>

                    <div className="jlt-hidden md:jlt-block jlt-border jlt-border-[#d9d9d9] jlt-border-b-0">
                        <div className="jlt-bg-[#EB5E81] jlt-px-4 jlt-py-8 jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
                            <h3 className="jlt-text-3xl jlt-text-white">{price2.title}</h3>
                            <p className="jlt-mt-1 jlt-text-sm jlt-text-[#f1f1f1]">
                                {price2.subtitle}
                            </p>
                        </div>
                        <div className="jlt-px-4 jlt-py-8">
                            <h4 className="jlt-leading-[40px] jlt-text-center jlt-font-bold">
                                <sup className="jlt-text-lg -jlt-top-4">$</sup>
                                <span>{price2.price}</span>
                            </h4>
                            <p className="jlt-text-sm jlt-text-center">{price2.priceDesc}</p>
                        </div>
                    </div>
                </div>

                <table className="jlt-border-collapse jlt-w-full">
                    <tbody>
                        {priceContent?.map((item, i) => (
                            <tr
                                key={i}
                                className="lg:jlt-border lg:jlt-border-[#d9d9d9] jlt-grid md:jlt-grid-cols-2 jlt-gap-4 lg:jlt-gap-0 lg:jlt-grid-cols-[2fr_1fr_1fr] odd:jlt-bg-[#FCFCFD] jlt-text-[#475467]">
                                <td className="jlt-hidden jlt-border-r jlt-py-4 jlt-px-8 lg:jlt-flex jlt-items-center jlt-justify-between">
                                    <span>{item.title}</span>
                                    {item?.tooltip ? (
                                        <Tooltip text={item?.tooltip || ""}>
                                            <StyledIcon
                                                color="#000"
                                                type="stroke"
                                                styles={{ width: "14px", height: "14px" }}>
                                                {getIcon("question")}
                                            </StyledIcon>
                                        </Tooltip>
                                    ) : null}
                                </td>
                                <td className="jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-border-l lg:jlt-border-t-0 lg:jlt-border-b-0 jlt-py-4 jlt-px-8 jlt-flex jlt-items-center jlt-justify-between lg:jlt-justify-center">
                                    <span className="lg:jlt-hidden jlt-block">{item.title}</span>
                                    {!!item.price1Type ? (
                                        getIcon("check")
                                    ) : item.price1.includes("N/A") ? (
                                        <StyledIcon color="#F04438">{getIcon("close")}</StyledIcon>
                                    ) : (
                                        item.price1
                                    )}
                                </td>
                                <td className="jlt-hidden jlt-border jlt-border-[#d9d9d9] jlt-border-l lg:jlt-border-t-0 lg:jlt-border-b-0 jlt-py-4 jlt-px-8 md:jlt-flex jlt-items-center jlt-justify-between lg:jlt-justify-center">
                                    <span className="lg:jlt-hidden jlt-block">{item.title}</span>
                                    {!!item.price2Type ? (
                                        getIcon("check")
                                    ) : item.price2.includes("N/A") ? (
                                        <StyledIcon color="#F04438">{getIcon("close")}</StyledIcon>
                                    ) : (
                                        item.price2
                                    )}
                                </td>
                            </tr>
                        ))}
                        <tr className="jlt-grid jlt-grid-cols-1 md:jlt-grid-cols-2 jlt-gap-4 lg:jlt-gap-0 lg:jlt-grid-cols-[2fr_1fr_1fr] odd:jlt-bg-[#FCFCFD] jlt-text-[#475467]">
                            <td className="jlt-hidden lg:jlt-block"></td>
                            <td className="jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-py-4 jlt-px-8 jlt-flex jlt-items-center jlt-justify-center">
                                {/* <SubscribeButton
                                    icon={getIcon("download")}
                                    attributes={{
                                        btnText: price1Btn?.btnText || "Free",
                                        listId: price1Btn?.listId,
                                        tagId: price1Btn?.tagId,
                                        btnColor: "#fff",
                                        btnBgColor: "#0f4ecc",
                                        border: {},
                                        borderRadius: "4",
                                        padding: {},
                                        width: "100%",
                                    }}
                                /> */}
                                <FreeDownload price1Btn={price1Btn} />
                            </td>
                            <td className="jlt-hidden jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-py-4 jlt-px-8 md:jlt-flex jlt-items-center jlt-justify-center">
                                <Link
                                    href={`/checkout?id=${price2Btn?.productId}&license=${
                                        price2Btn?.productVariation
                                    }${
                                        price2Btn?.couponCode
                                            ? `&coupon=${price2Btn.couponCode}`
                                            : ""
                                    }`}
                                    className="jlt-w-full jlt-py-[10px] jlt-px-4 jlt-rounded jlt-text-white jlt-text-sm jlt-font-semibold jlt-bg-[#eb5e81] jlt-flex jlt-items-center jlt-justify-center jlt-gap-2">
                                    <span>Pro</span>
                                    <svg
                                        width="12"
                                        height="18"
                                        viewBox="0 0 12 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.97501 11.0414L2.25 16.5L5.6913 14.4352C5.80354 14.3679 5.85966 14.3342 5.91957 14.3211C5.97256 14.3094 6.02744 14.3094 6.08043 14.3211C6.14034 14.3342 6.19646 14.3679 6.3087 14.4352L9.75 16.5L9.02572 11.0409M11.25 6.75C11.25 9.6495 8.8995 12 6 12C3.10051 12 0.75 9.6495 0.75 6.75C0.75 3.85051 3.10051 1.5 6 1.5C8.8995 1.5 11.25 3.85051 11.25 6.75Z"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="jlt-block md:jlt-hidden jlt-mt-16">
                <div className="jlt-grid jlt-grid-cols-1 md:jlt-grid-cols-2 jlt-gap-4 lg:jlt-gap-0 lg:jlt-grid-cols-[2fr_1fr_1fr]">
                    <div className="jlt-hidden lg:jlt-block"></div>

                    <div className="jlt-hidden md:jlt-block jlt-border jlt-border-[#d9d9d9] jlt-border-b-0">
                        <div className="jlt-bg-[#f5f5f5] jlt-px-4 jlt-py-8 jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
                            <h3 className="jlt-text-3xl">{price1.title}</h3>
                            <p className="jlt-mt-1 jlt-text-sm">{price1.subtitle}</p>
                        </div>
                        <div className="jlt-px-4 jlt-py-8">
                            <h4 className="jlt-leading-[40px] jlt-text-center jlt-font-bold">
                                <sup>$</sup>
                                <span>{price1.price}</span>
                            </h4>
                            <p className="jlt-text-sm jlt-text-center">{price1.priceDesc}</p>
                        </div>
                    </div>

                    <div className="jlt-border jlt-border-[#d9d9d9] jlt-border-b-0">
                        <div className="jlt-bg-[#EB5E81] jlt-px-4 jlt-py-8 jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
                            <h3 className="jlt-text-3xl jlt-text-white">{price2.title}</h3>
                            <p className="jlt-mt-1 jlt-text-sm jlt-text-[#f1f1f1]">
                                {price2.subtitle}
                            </p>
                        </div>
                        <div className="jlt-px-4 jlt-py-8">
                            <h4 className="jlt-leading-[40px] jlt-text-center jlt-font-bold">
                                <sup>$</sup>
                                <span>{price2.price}</span>
                            </h4>
                            <p className="jlt-text-sm jlt-text-center">{price2.priceDesc}</p>
                        </div>
                    </div>
                </div>

                <table className="jlt-border-collapse jlt-w-full">
                    <tbody>
                        {priceContent?.map((item, i) => (
                            <tr
                                key={i}
                                className="lg:jlt-border lg:jlt-border-[#d9d9d9] jlt-grid md:jlt-grid-cols-2 jlt-gap-4 lg:jlt-gap-0 lg:jlt-grid-cols-[2fr_1fr_1fr] odd:jlt-bg-[#FCFCFD] jlt-text-[#475467]">
                                <td className="jlt-hidden jlt-border-r jlt-py-4 jlt-px-8 lg:jlt-flex jlt-items-center jlt-justify-between">
                                    <span>{item.title}</span>
                                    {item?.tooltip ? (
                                        <Tooltip text={item?.tooltip || ""}>
                                            <StyledIcon
                                                color="#000"
                                                type="stroke"
                                                styles={{ width: "14px", height: "14px" }}>
                                                {getIcon("question")}
                                            </StyledIcon>
                                        </Tooltip>
                                    ) : null}
                                </td>
                                <td className="jlt-hidden jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-border-l lg:jlt-border-t-0 lg:jlt-border-b-0 jlt-py-4 jlt-px-8 md:jlt-flex jlt-items-center jlt-justify-between lg:jlt-justify-center">
                                    <span className="lg:jlt-hidden jlt-block">{item.title}</span>
                                    {!!item.price1Type ? (
                                        getIcon("check")
                                    ) : item.price1.includes("N/A") ? (
                                        <StyledIcon color="#F04438">{getIcon("close")}</StyledIcon>
                                    ) : (
                                        item.price1
                                    )}
                                </td>
                                <td className="jlt-border jlt-border-[#d9d9d9] jlt-border-l lg:jlt-border-t-0 lg:jlt-border-b-0 jlt-py-4 jlt-px-8 jlt-flex jlt-items-center jlt-justify-between lg:jlt-justify-center">
                                    <span className="lg:jlt-hidden jlt-block">{item.title}</span>
                                    {!!item.price2Type ? (
                                        getIcon("check")
                                    ) : item.price2.includes("N/A") ? (
                                        <StyledIcon color="#F04438">{getIcon("close")}</StyledIcon>
                                    ) : (
                                        item.price2
                                    )}
                                </td>
                            </tr>
                        ))}
                        <tr className="jlt-grid md:jlt-grid-cols-2 jlt-gap-4 lg:jlt-gap-0 lg:jlt-grid-cols-[2fr_1fr_1fr] odd:jlt-bg-[#FCFCFD] jlt-text-[#475467]">
                            <td className="jlt-hidden lg:jlt-block"></td>
                            <td className="jlt-hidden jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-py-4 jlt-px-8 md:jlt-flex jlt-items-center jlt-justify-center">
                                <SubscribeButton
                                    icon={getIcon("download")}
                                    attributes={{
                                        btnText: price1Btn?.btnText || "Free",
                                        listId: price1Btn?.listId,
                                        tagId: price1Btn?.tagId,
                                        btnColor: "#fff",
                                        btnBgColor: "#0f4ecc",
                                        border: {},
                                        borderRadius: "4",
                                        padding: {},
                                        width: "100%",
                                    }}
                                />
                            </td>
                            <td className="jlt-border jlt-border-[#d9d9d9] jlt-border-r jlt-py-4 jlt-px-8 jlt-flex jlt-items-center jlt-justify-center">
                                <Link
                                    href={`/checkout?id=${price2Btn?.productId}&license=${
                                        price2Btn?.productVariation
                                    }${
                                        price2Btn?.couponCode
                                            ? `&coupon=${price2Btn.couponCode}`
                                            : ""
                                    }`}
                                    className="jlt-w-full jlt-py-[10px] jlt-px-4 jlt-rounded jlt-text-white jlt-text-sm jlt-font-semibold jlt-bg-[#eb5e81] jlt-flex jlt-items-center jlt-justify-center jlt-gap-2">
                                    <span>Pro</span>
                                    <svg
                                        width="12"
                                        height="18"
                                        viewBox="0 0 12 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.97501 11.0414L2.25 16.5L5.6913 14.4352C5.80354 14.3679 5.85966 14.3342 5.91957 14.3211C5.97256 14.3094 6.02744 14.3094 6.08043 14.3211C6.14034 14.3342 6.19646 14.3679 6.3087 14.4352L9.75 16.5L9.02572 11.0409M11.25 6.75C11.25 9.6495 8.8995 12 6 12C3.10051 12 0.75 9.6495 0.75 6.75C0.75 3.85051 3.10051 1.5 6 1.5C8.8995 1.5 11.25 3.85051 11.25 6.75Z"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdvancedPricing;
