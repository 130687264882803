"use client";

import SyntaxHighlighter from "react-syntax-highlighter";
import docco from "./docco";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState, Fragment, useEffect } from "react";
import { getIcon } from "@litonarefin/utils/icons";

export const Html = ({ attributes, originalContent }) => {
    const [copiedText, setCopiedText] = useState({ copied: false });

    useEffect(() => {
        let interval;
        if (copiedText?.copied === true) {
            interval = setInterval(() => {
                setCopiedText({ copied: false });
            }, 6000);
        }
        return () => clearInterval(interval);
    }, [copiedText]);

    return (
        <div {...(attributes?.anchor && { id: attributes?.anchor })} className="jlt-relative">
            <SyntaxHighlighter language="html" style={docco}>
                {originalContent}
            </SyntaxHighlighter>

            <CopyToClipboard
                text={`${originalContent}`}
                onCopy={() => setCopiedText({ copied: true })}>
                <button
                    className={`jlt-absolute jlt-top-1 jlt-right-[6px] jlt-border jlt-border-[#D0D5DD] jlt-py-2 jlt-px-4 jlt-rounded-[4px] jlt-text-xs jlt-flex jlt-items-center jlt-gap-2`}>
                    {copiedText?.copied ? (
                        <Fragment>{getIcon("check")}</Fragment>
                    ) : (
                        <Fragment>{getIcon("copy")}</Fragment>
                    )}
                </button>
            </CopyToClipboard>
        </div>
    );
};

export default Html;
