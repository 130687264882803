"use client";
import React, { useState } from "react";
import Modal from "@litonarefin/components/Modal";

export function JltContainerScroll({ attributes }) {
    const [isOpenModal, setIsModalOpen] = useState(false);

    const { img, video } = attributes;

    return (
        <div className="jlt-relative jlt-shadow-[0px_-64px_72px_0px_#3440541A] jlt-rounded-2xl jlt-overflow-hidden jlt-bg-[linear-gradient(#E2E8F0_0%,#FFFFFF00_100%)] jlt-p-[1px]">
            <img
                src={img.url}
                alt={img.alt}
                height={520}
                width={1400}
                className="jlt-mx-auto jlt-rounded-2xl jlt-object-cover jlt-h-full jlt-object-left-top"
            />
            <div
                className="jlt-absolute jlt-top-0 jlt-left-0 jlt-right-0 jlt-h-full jlt-rounded-2xl"
                style={{
                    background:
                        "linear-gradient(180deg, rgba(255, 255, 255, 0) 66.67%, #FEFEFF 100%)",
                }}
            />
            <div className="jlt-absolute jlt-w-max jlt-h-max jlt-top-[calc(50%+40px)] jlt-left-[calc(50%+40px)] jlt-right-0">
                <div className="jlt-relative jlt-inline-block jlt-z-50">
                    <div className="jlt-absolute">
                        <div className="jlt-waves-block">
                            <div className="jlt-waves jlt-wave-1"></div>
                            <div className="jlt-waves jlt-wave-2"></div>
                            <div className="jlt-waves jlt-wave-3"></div>
                        </div>
                    </div>
                    <div
                        onClick={() => setIsModalOpen(true)}
                        className="jlt-absolute -jlt-top-[65px] -jlt-left-[64px] jlt-w-20 jlt-h-20 jlt-flex jlt-items-center jlt-justify-center jlt-rounded-full jlt-cursor-pointer"
                        style={{
                            background:
                                "linear-gradient(84.41deg, #1F1FC1 -0.25%, #6C6CFF 99.75%)",
                        }}>
                        <svg
                            width="33"
                            className="jlt-ml-2"
                            height="37"
                            viewBox="0 0 33 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.784668 4.92833C0.784668 1.84913 4.118 -0.0753716 6.78467 1.46423L30.7847 15.3206C33.4513 16.8602 33.4513 20.7092 30.7847 22.2488L6.78467 36.1052C4.118 37.6448 0.784669 35.7203 0.784669 32.6411L0.784668 4.92833Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <style jsx>{`
                        @keyframes waves {
                            0% {
                                -webkit-transform: scale(0.2, 0.2);
                                transform: scale(0.2, 0.2);
                                opacity: 0;
                                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                            }
                            50% {
                                opacity: 0.9;
                                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
                            }
                            100% {
                                -webkit-transform: scale(0.9, 0.9);
                                transform: scale(0.9, 0.9);
                                opacity: 0;
                                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                            }
                        }

                        .jlt-waves-block {
                            .jlt-waves {
                                position: absolute;
                                width: 150px;
                                height: 150px;
                                background: rgba(0, 0, 0, 0.3);
                                opacity: 0;
                                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                                border-radius: 100%;
                                right: -50px;
                                bottom: -50px;
                                z-index: -1;
                                -webkit-animation: waves 3s ease-in-out infinite;
                                animation: waves 3s ease-in-out infinite;
                            }

                            .jlt-wave-1 {
                                -webkit-animation-delay: 0s;
                                animation-delay: 0s;
                            }

                            .jlt-wave-2 {
                                -webkit-animation-delay: 1s;
                                animation-delay: 1s;
                            }

                            .jlt-wave-3 {
                                -webkit-animation-delay: 2s;
                                animation-delay: 2s;
                            }
                        }
                    `}</style>
                </div>
            </div>
            {isOpenModal ? (
                <Modal
                    onCloseModal={() => setIsModalOpen(false)}
                    closeOutside={true}
                    width="960px"
                    height="540px">
                    <iframe
                        className="jlt-h-full jlt-w-full lg:jlt-w-[960px] lg:jlt-h-[540px]"
                        src={video}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen></iframe>
                </Modal>
            ) : null}
        </div>
    );
}
