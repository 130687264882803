//minify css code
export default function getMinifyCSS(cssStr) {
    return getEmptySelector(cssStr.split("\n").join("").replace(/\s+/g, " "));
}

//check empty selection
function getEmptySelector(cls) {
    let result = cls.includes("{ }");
    let finalResult = result ? "" : cls;

    return finalResult;
}
