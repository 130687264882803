"use client";

import { useEffect, useState, useMemo } from "react";
import { sortNestedById } from "@litonarefin/utils/flatToNestedArray";
import axios from "axios";
import { LoginPopup } from "@litonarefin/components/LoginPopup/LoginPopup";
import Modal from "@litonarefin/components/Modal";
import ReviewForm from "./ReviewForm";
import { useSession } from "next-auth/react";
import Pagination from "./Pagination";
import SingleReview from "./SingleReview";
import Rating from "react-star-review";

const ReviewList = ({ product_id, productName, totalReview, averageReview }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

    const [data, setData] = useState([]);

    const [activePage, setActivePage] = useState(1);
    const [nextPage, setNextPage] = useState(2);
    const [prevPage, setPrevPage] = useState(1);

    const session = useSession();

    const fetchReviewes = useMemo(
        () => async (id, activePage) => {
            let {
                data: { data: reviewsFetch },
            } = await axios.get(`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/reviews`, {
                params: { product_id: id, activePage },
            });

            let sortedReviews = sortNestedById(reviewsFetch);
            let filteredReviews = sortedReviews.filter((item) => {
                const givenDate = new Date(item.date_created);
                const currentDate = new Date();
                const maxGapMinutes = 3;
                const timeDiffMinutes =
                    Math.abs(currentDate.getTime() - givenDate.getTime()) / (1000 * 60);
                return (
                    item.status == "approved" ||
                    (item.status == "hold" &&
                        item.reviewer == name &&
                        timeDiffMinutes <= maxGapMinutes)
                );
            });
            setData(filteredReviews.reverse());
            setData(reviewsFetch);
        },
        [product_id, activePage]
    );

    useEffect(() => {
        fetchReviewes(product_id, activePage);
    }, [activePage]);

    return (
        <div>
            <h3 className="jlt-text-center">What Our Customers Say</h3>
            <div className="jlt-mt-16 jlt-flex jlt-items-center jlt-justify-between jlt-bg-white jlt-p-8 jlt-shadow-[0px_0px_48px_rgba(16,24,40,0.04)] jlt-rounded-lg">
                <div>
                    <h4 className="jlt-text-2xl jlt-text-[#101828] jlt-font-semibold">
                        Overall Rating
                    </h4>
                    <div className="jlt-flex jlt-items-center jlt-gap-1 jlt-mt-1">
                        <span className="jlt-text-lg jlt-font-extrabold">{averageReview}</span>
                        <div className="jlt-mb-1">
                            <Rating
                                interactive={false}
                                rating={averageReview || 0}
                                count={5}
                                size={18}
                            />
                        </div>
                        <span className="jlt-text-xs jlt-text-gray-600 jlt-ml-2">
                            {totalReview || 0} Reviews
                        </span>
                    </div>
                </div>

                {!!session?.data?.user?.id ? (
                    <button
                        onClick={() => setIsOpenModal(true)}
                        className="jlt-bg-[#FB346D] jlt-py-[10px] jlt-px-4 jlt-text-white jlt-text-sm jlt-font-semibold jlt-rounded jlt-border jlt-border-[#FB346D]">
                        Write a Review
                    </button>
                ) : (
                    <button
                        onClick={() => setIsOpenLoginModal(true)}
                        className="jlt-bg-[#FB346D] jlt-py-[10px] jlt-px-4 jlt-text-white jlt-text-sm jlt-font-semibold jlt-rounded jlt-border jlt-border-[#FB346D]">
                        Login
                    </button>
                )}

                {isOpenModal && !!session?.data?.user?.id ? (
                    <Modal onCloseModal={() => setIsOpenModal(false)}>
                        <ReviewForm
                            product_id={product_id}
                            session={session}
                            productName={productName}
                        />
                    </Modal>
                ) : null}

                {isOpenLoginModal && !session?.data?.user?.id ? (
                    <Modal onCloseModal={() => setIsOpenLoginModal(false)}>
                        <LoginPopup isRegister={true} reload={false} />
                    </Modal>
                ) : null}
            </div>

            <div className="jlt-flex jlt-flex-col jlt-gap-y-12 jlt-mt-12">
                {data?.length > 0 ? (
                    <>
                        {data.reverse().map((item, i) => {
                            if (
                                item?.status === "hold" &&
                                session?.data?.user?.email === item.reviewer_email
                            ) {
                                return <SingleReview key={i} reviewData={item} />;
                            }

                            if (item?.status === "approved") {
                                return <SingleReview key={i} reviewData={item} />;
                            }
                        })}

                        <Pagination
                            activePage={activePage}
                            setActivePage={setActivePage}
                            nextPage={nextPage}
                            setNextPage={setNextPage}
                            prevPage={prevPage}
                            setPrevPage={setPrevPage}
                            totalPage={totalReview / 6}
                        />
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default ReviewList;
