"use client";

import { useState } from "react";

function Tooltip({ text, children }) {
    const [openTooltip, setOpenTooltip] = useState(false);

    return (
        <div className="jlt-relative">
            <div
                onMouseEnter={() => setOpenTooltip(true)}
                onMouseLeave={() => setOpenTooltip(false)}>
                {children}
            </div>
            {!!openTooltip ? (
                <span className="jlt-absolute jlt-bg-[#1d1d1d] jlt-text-[#f1f1f1] jlt-text-sm jlt-py-2 jlt-px-3 jlt-rounded jlt-bottom-6 jlt-w-52 -jlt-translate-x-[97px] after:jlt-content-[''] after:jlt-absolute after:jlt-top-full after:jlt-left-1/2 after:jlt-transition-opacity after:jlt-ml-[-5px] after:jlt-border-[5px] after:jlt-border-t-black after:jlt-border-r-transparent after:jlt-border-b-transparent after:jlt-border-l-transparent">
                    {text}
                </span>
            ) : null}
        </div>
    );
}

export default Tooltip;

// content: " ";
// position: absolute;
// top: 100%;
// left: 50%;
// transition: opacity .2s;
// margin-left: -5px;
// border-width: 5px;
// border-style: solid;
// border-color: black transparent transparent transparent;
