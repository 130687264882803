export const getColor = (color) => {
    const colors = {
        base: "#ffffff",
        contrast: "#000000",
        primary: "#9dff20",
        secondary: "#345c00",
        tertiary: "#f6f6f6",
    };

    if (Object.keys(colors).includes(color)) {
        return `${colors[color]}`;
    }
    return color;
};
