"use client";

import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import { LAPTOP, TABLET, MOBILE } from "@litonarefin/utils/breakpoint";

function Buttons({ attributes, children }) {
    return (
        <>
            <div className="jlt_b_btns">{children}</div>
            <style jsx>{`
                .jlt_b_btns {
                    display: ${attributes?.display || "flex"};
                    align-items: center;
                    justify-content: ${attributes?.align?.desktop || "start"};
                    flex-direction: ${attributes?.direction?.desktop || "row"};
                    gap: ${attributes?.gap?.desktop || 20}px;
                    ${getDimensionCSS(attributes?.margin?.desktop, "margin")}
                    > .jlt_b_btn {
                        width: ${attributes?.width?.desktop || "auto"};
                        ${attributes?.width?.desktop === "100%" ? `text-align: center;` : ""}
                    }
                }

                //laptop device
                @media only screen and (max-width: ${LAPTOP}px) {
                    .jlt_b_btns {
                        ${attributes?.align?.laptop
                            ? `justify-content: ${attributes?.align?.laptop};`
                            : ""}
                        ${attributes?.direction?.laptop
                            ? `flex-direction: ${attributes?.direction?.laptop};`
                            : ""}
                        ${attributes?.gap?.laptop ? `gap: ${attributes?.gap?.laptop}px;` : ""}
                        ${getDimensionCSS(attributes?.margin?.laptop, "margin")
                            ? getDimensionCSS(attributes?.margin?.laptop, "margin")
                            : ""}
                        > .jlt_b_btn {
                            ${attributes?.width?.laptop
                                ? `width: ${attributes?.width?.laptop};`
                                : ""};
                            ${attributes?.width?.laptop === "100%" ? `text-align: center;` : ""}
                        }
                    }
                }

                //tablet device
                @media only screen and (max-width: ${TABLET}px) {
                    .jlt_b_btns {
                        ${attributes?.align?.tablet
                            ? `justify-content: ${attributes?.align?.tablet};`
                            : ""}
                        ${attributes?.direction?.tablet
                            ? `flex-direction: ${attributes?.direction?.tablet};`
                            : ""}
                        ${attributes?.gap?.tablet ? `gap: ${attributes?.gap?.tablet}px;` : ""}
                        ${getDimensionCSS(attributes?.margin?.tablet, "margin")
                            ? getDimensionCSS(attributes?.margin?.tablet, "margin")
                            : ""}
                        > .jlt_b_btn {
                            ${attributes?.width?.tablet
                                ? `width: ${attributes?.width?.tablet};`
                                : ""};
                            ${attributes?.width?.tablet === "100%" ? `text-align: center;` : ""}
                        }
                    }
                }

                // mobile device
                @media only screen and (max-width: ${MOBILE}px) {
                    .jlt_b_btns {
                        ${attributes?.align?.mobile
                            ? `justify-content: ${attributes?.align?.mobile};`
                            : ""}
                        ${attributes?.direction?.mobile
                            ? `flex-direction: ${attributes?.direction?.mobile};`
                            : ""}
                        ${attributes?.gap?.mobile ? `gap: ${attributes?.gap?.mobile}px;` : ""}
                        ${getDimensionCSS(attributes?.margin?.mobile, "margin")
                            ? getDimensionCSS(attributes?.margin?.mobile, "margin")
                            : ""}
                        > .jlt_b_btn {
                            ${attributes?.width?.mobile
                                ? `width: ${attributes?.width?.mobile};`
                                : ""};
                            ${attributes?.width?.mobile === "100%" ? `text-align: center;` : ""}
                        }
                    }
                }
            `}</style>
        </>
    );
}

export default Buttons;
