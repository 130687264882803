export const removeDuplicates = (arr) => {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
        if (uniqueIds.has(obj.id)) {
            return false;
        }
        uniqueIds.add(obj.id);
        if (obj.children && obj.children.length > 0) {
            obj.children = removeDuplicates(obj.children);
        }
        return true;
    });
};

export const setDepth = (arr, depth = 0) => {
    return arr.map((obj) => {
        const newObj = { ...obj, depth };
        if (obj.children && obj.children.length > 0) {
            newObj.children = setDepth(obj.children, depth + 1);
        }
        return newObj;
    });
};

export const setChildren = (arr) => {
    return arr.map((obj) => {
        const newObj = { ...obj, children: [] };
        return newObj;
    });
};

export const sortNestedById = (arr) => {
    arr.sort((a, b) => {
        if (a.id < b.id) {
            return -1;
        } else if (a.id > b.id) {
            return 1;
        } else {
            return 0;
        }
    });

    arr.forEach((item) => {
        if (item.children && item.children.length > 0) {
            sortNestedById(item.children);
        }
    });

    return arr;
};

export const convertFlatToNestedArray = (flatArray) => {
    const nestedArray = [];
    if (flatArray.length == 0) return;
    let newArray = setChildren(flatArray);

    // create a dictionary to store all items by their ID
    const itemById = {};
    newArray.forEach((item) => (itemById[item.id] = item));

    // iterate over all items
    newArray.forEach((item) => {
        if (item.parent === 0) {
            // if this item has no parent, add it directly to the nested array
            nestedArray.push(item);
        } else {
            // if this item has a parent, add it to its parent's "children" array
            const parentItem = itemById[item.parent];
            if (parentItem?.children) {
                parentItem.children.push(item);
            }
        }
    });

    return sortNestedById(setDepth(removeDuplicates(nestedArray)));
};
