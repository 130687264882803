"use client";

import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import { getCleanSpacing } from "@litonarefin/utils/spacing";

export default function Video({ attributes }) {
    return (
        <figure
            {...(attributes?.anchor && { id: attributes?.anchor })}
            className="jlt-video-wrapper">
            <video
                id={attributes?.id}
                controls={attributes?.jltControls}
                preload={attributes?.preload}
                // onContextMenu="return false;"
                className="jlt-webm-video"
                src={attributes?.src}
                poster={attributes?.poster}
                playsInline={attributes?.jltPlayInline ? true : false}
                autoPlay={attributes?.jltAutoPlay ? true : false}
                muted={attributes?.jltMuted}
                loop={attributes?.jltLoop ? true : false}
            />
            <style jsx>{`
                .jlt-video-wrapper {
                    ${getMinifyCSS(`
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.margin?.top)
                                ? `margin-top: ${getCleanSpacing(
                                      attributes?.style?.spacing?.margin?.top
                                  )};`
                                : ""
                        }
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.margin?.bottom)
                                ? `margin-bottom: ${getCleanSpacing(
                                      attributes?.style?.spacing?.margin?.bottom
                                  )};`
                                : ""
                        }
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.margin?.left)
                                ? `margin-left: ${getCleanSpacing(
                                      attributes?.style?.spacing?.margin?.left
                                  )};`
                                : ""
                        }
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.margin?.right)
                                ? `margin-right: ${getCleanSpacing(
                                      attributes?.style?.spacing?.margin?.right
                                  )};`
                                : ""
                        }
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.padding?.top)
                                ? `padding-top: ${getCleanSpacing(
                                      attributes?.style?.spacing?.padding?.top
                                  )};`
                                : ""
                        }
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.padding?.bottom)
                                ? `padding-bottom: ${getCleanSpacing(
                                      attributes?.style?.spacing?.padding?.bottom
                                  )};`
                                : ""
                        }
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.padding?.left)
                                ? `padding-left: ${getCleanSpacing(
                                      attributes?.style?.spacing?.padding?.left
                                  )};`
                                : ""
                        }
                        ${
                            getCleanSpacing(attributes?.style?.spacing?.padding?.right)
                                ? `padding-right: ${getCleanSpacing(
                                      attributes?.style?.spacing?.padding?.right
                                  )};`
                                : ""
                        }
                    `)}
                }
            `}</style>
        </figure>
    );
}
