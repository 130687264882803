import Rating from "react-star-review";
import startCase from "lodash/startCase";

const SingleReview = ({ reviewData }) => {
    return (
        <div className={`jlt-rounded-lg jlt-px-8`}>
            <div className="jlt-flex jlt-items-center jlt-justify-between">
                <div className="jlt-flex jlt-items-center jlt-gap-4">
                    <img
                        className="jlt-rounded-full"
                        src={reviewData?.reviewer_avatar_urls?.["48"]}
                        alt={reviewData?.reviewer}
                        width={56}
                        height={56}
                    />
                    <div>
                        <h2 className="jlt-text-[--primary] jlt-font-bold jlt-text-base">
                            {startCase(reviewData?.reviewer.toLowerCase())}
                        </h2>
                        <span className="jlt-text-sm jlt-text-[#98A2B3]">
                            1 day ago
                        </span>
                    </div>
                </div>
                <Rating
                    interactive={false}
                    rating={reviewData?.rating || 0}
                    count={5}
                    size={18}
                />
            </div>
            <div className="jlt-mt-4">
                {/* <span className="jlt-text-sm jlt-text-[#72798A] jlt-font-normal">
                            {reviewData?.review}
                            </span> */}
                <h3 className="jlt-mt-2 jlt-text-[#475467] jlt-font-bold jlt-text-base">
                    {reviewData?.title}
                </h3>
                <p
                    className="jlt-text-[#475467] jlt-mt-2 jlt-text-base"
                    dangerouslySetInnerHTML={{
                        __html: reviewData?.review,
                    }}
                />
            </div>

            {reviewData?.status == "hold" && (
                <span className="jlt-italic">
                    Your review is awaiting moderation.
                </span>
            )}
        </div>
    );
};

export default SingleReview;
