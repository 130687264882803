import { sanitize } from "@litonarefin/utils/miscellaneous";

const ListItem = ({ attributes }) => {
    return (
        <li
            {...(attributes?.anchor && { id: attributes?.anchor })}
            dangerouslySetInnerHTML={{ __html: sanitize(attributes?.content) }}
        />
    );
};

export default ListItem;
