import { useState } from "react";

function FilterTabList({ tabs, filteredProducts, setActiveProducts }) {
    const [activeTab, setActiveTab] = useState(tabs?.[0]?.value);

    return (
        <div className="jlt-flex jlt-items-center jlt-justify-center jlt-gap-x-4">
            {tabs?.map((tab) => (
                <button
                    key={tab?.value}
                    type="button"
                    className={`jlt-p-[10px] jlt-rounded jlt-text-sm jlt-font-semibold jlt-text-[#475467] jlt-cursor-pointer jlt-relative ${
                        activeTab === tab?.value ? " jlt-text-white jlt-bg-[#fb346d]" : ""
                    }`}
                    onClick={() => {
                        setActiveTab(tab?.value);
                        setActiveProducts(filteredProducts?.[tab?.value]);
                    }}>
                    {tab?.label}
                </button>
            ))}
        </div>
    );
}

export default FilterTabList;
