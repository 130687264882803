import { DocsSearch } from "@litonarefin/components/DocsSearch";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";

const DocsSearchBlock = () => {
    const siteSource = getSourceSite();

    return <DocsSearch pageSlug={siteSource} />;
};

export default DocsSearchBlock;
