"use client";

// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";

import { getBorderCSS, getBorderRadiusCSS } from "@litonarefin/utils/getBorderCSS";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import getTypographyCSS from "@litonarefin/utils/getTypographyCSS";
import { Fragment } from "react";

const InfoBox = ({ attributes }) => {
    const {
        alignment = "start",
        bgColor,
        bgcolorHover,
        transition = "100",
        borders = { width: "2px", style: "solid", color: "#EBE9FE" },
        bordersHover,
        borderRadius = { top: "8px", right: "8px", bottom: "8px", left: "8px" },
        padding = { top: "32px", right: "32px", bottom: "32px", left: "32px" },
        imgURL,
        imgAlt,
        imgURLHover,
        imgAltHover,
        imgSize = "64",
        title,
        titleColor = "#344054",
        titleColorHover,
        titleTypo = {
            textFont: { font: "", weight: 700 },
            textSize: "24px",
            textLineHeight: "32px",
        },
        titleTag = "h3",
        titleGap = "32",
        desc,
        descColor = "#475467",
        descColorHover,
        descTypo = {
            textFont: { font: "", weight: 400 },
            textSize: "16px",
            textLineHeight: "24px",
        },
        descGap = "16",
    } = attributes;
    // const allAOS = getAOSAnimation(attributes);

    const { typography: titleTypgraphy } = getTypographyCSS(titleTypo);
    const { typography: descTypgraphy } = getTypographyCSS(descTypo);
    const bordersCss = getBorderCSS(borders);
    const bordersCssHover = getBorderCSS(bordersHover);
    const borderRadiusCss = getBorderRadiusCSS(borderRadius);
    const paddingCss = getDimensionCSS(padding, "padding");

    const CustomHeading = titleTag;

    return (
        <Fragment>
            <div
                // {...allAOS}
                className="jlt-info-box-wrapper jlt-cursor-pointer"
                {...(attributes?.anchor && { id: attributes?.anchor })}>
                {imgURL ? (
                    <div className="jlt-info-box-img-wrapper">
                        {imgURL ? (
                            <img
                                className="jlt-info-box-img"
                                width={imgSize}
                                height={imgSize}
                                src={imgURL}
                                alt={imgAlt || "Info Box Image"}
                            />
                        ) : null}
                        {imgURLHover ? (
                            <img
                                className="jlt-info-box-hover-img"
                                width={imgSize}
                                height={imgSize}
                                src={imgURLHover}
                                alt={imgAltHover || "Info Box Image"}
                            />
                        ) : null}
                    </div>
                ) : null}
                <CustomHeading className="jlt-info-box-title">{title}</CustomHeading>
                <p className="jlt-info-box-description">{desc}</p>
            </div>
            <style jsx>{`
                .jlt-info-box-wrapper {
                    ${paddingCss ? paddingCss : ""}
                    ${bgColor ? `background: ${bgColor};` : ""}
                    ${bordersCss ? bordersCss : ""}
                    ${borderRadiusCss ? borderRadiusCss : ""}
                    ${transition ? `transition: all ${transition}ms;` : ""}
                    > .jlt-info-box-img-wrapper {
                        display: flex;
                        align-items: center;
                        ${alignment === "start" ? `justify-content: start;` : ""}
                        ${alignment === "center" ? `justify-content: center;` : ""}
                        ${alignment === "end" ? `justify-content: end;` : ""}
                        position: relative;
                        ${imgURLHover
                            ? `> .jlt-info-box-img {
                                    opacity: 1;
                                    ${transition ? `transition: all ${transition}ms;` : ""}
                                }
                                > .jlt-info-box-hover-img {
                                    position: absolute;
                                    ${alignment === "start" ? `top:0; left:0;` : ""}
                                    ${
                                        alignment === "center"
                                            ? `top:0; left:0; right: 0; margin: auto;`
                                            : ""
                                    }
                                    ${alignment === "end" ? `top:0; right:0;` : ""}
                                    opacity: 0;
                                    ${transition ? `transition: all ${transition}ms;` : ""}
                                }`
                            : ""}
                    }
                    > .jlt-info-box-title {
                        ${titleTypgraphy}
                        ${titleColor ? `color: ${titleColor};` : ""}
                        ${titleGap ? `margin-top: ${titleGap}px;` : ""}
                        ${alignment === "start" ? `text-align: left;` : ""}
                        ${alignment === "center" ? `text-align: center;` : ""}
                        ${alignment === "end" ? `text-align: right;` : ""}
                        ${transition ? `transition: all ${transition}ms;` : ""}
                    }
                    > .jlt-info-box-description {
                        ${descTypgraphy}
                        ${descColor ? `color: ${descColor};` : ""}
                        ${descGap ? `margin-top: ${descGap}px;` : ""}
                        ${alignment === "start" ? `text-align: left;` : ""}
                        ${alignment === "center" ? `text-align: center;` : ""}
                        ${alignment === "end" ? `text-align: right;` : ""}
                        ${transition ? `transition: all ${transition}ms;` : ""}
                    }

                    &:hover {
                        ${bgcolorHover ? `background: ${bgcolorHover};` : ""}
                        ${bordersCssHover ? bordersCssHover : ""}
                        > .jlt-info-box-title {
                            ${titleColorHover ? `color: ${titleColorHover};` : ""}
                        }
                        > .jlt-info-box-description {
                            ${descColorHover ? `color: ${descColorHover};` : ""}
                        }
                        ${imgURLHover
                            ? `> .jlt-info-box-img-wrapper {
                                    > .jlt-info-box-img {
                                        opacity: 0;
                                    }
                                    > .jlt-info-box-hover-img {
                                        opacity: 1;
                                    }
                                }`
                            : ""}
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default InfoBox;
