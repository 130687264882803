// object to string (only for getTypographyCSS function)
function objToString(obj) {
    let str = "";
    let validObj = {};
    Object.keys(obj).map((k) => {
        if (obj[k] === undefined) {
            return;
        }
        if (obj[k] === "undefined" || !obj[k]) {
            return;
        }
        if (obj[k] !== "") {
            validObj[k] = obj[k];
        }
    });

    for (const [p, val] of Object.entries(validObj)) {
        str += `${p}:${val};`;
    }
    return str;
}

//typography
const getTypographyCSS = (typo) => {
    const typoObj = {
        "font-family": typo?.textFont?.font,
        "font-weight": typo?.textFont?.weight,
        "font-size": typo?.textSize,
        "text-transform": typo?.textTransform,
        "font-style": typo?.textStyle,
        "text-decoration": typo?.textDecoration,
        "letter-spacing": typo?.textLetterSpacing,
        "line-height": typo?.textLineHeight,
        "word-Spacing": typo?.textWordSpacing,
    };

    let typography = objToString(typoObj);

    return { typography };
};

export default getTypographyCSS;
