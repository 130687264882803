"use client";

import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";

const Button = ({ data }) => {
    return (
        <div className="btn-wrapper jlt-flex jlt-mt-4">
            <button
                className={`btn jlt-border  jlt-px-6 jlt-rounded jlt-inline-block jlt-font-semibold ${data?.element === "custom_submit_button" ? "jlt-py-2 jlt-mb-2" : "jlt-py-3"}`}
                type={data.attributes.type || "submit"}>
                {data.settings.button_ui.text}
            </button>
            <style jsx>{`
                ${getMinifyCSS(`
                    .btn-wrapper {
                       justify-content: ${data.settings.align == "left" ? "start" : data.settings.align == "right" ? "end" : "center"};
                    }
                   .btn {
                        width: ${data?.settings.normal_styles?.minWidth};
                        background: ${data.settings?.normal_styles?.backgroundColor || data.settings?.background_color};
                        color: ${data.settings?.normal_styles?.color || data.settings?.color};
                        border-color: ${data.settings?.normal_styles?.borderColor};
                        &:hover{
                            background: ${data.settings?.hover_styles?.backgroundColor};
                            color: ${data.settings?.hover_styles?.color};
                            border-color: ${data.settings?.hover_styles?.borderColor};
                        }

                    } 
                `)}
            `}</style>
        </div>
    );
};

export default Button;
