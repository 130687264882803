"use client";

import { handleToastr } from "@litonarefin/utils/handleToastr";
import axios from "axios";
import { useRef, useState } from "react";
import Loader from "@litonarefin/components/Loader";

function JltAppsumoCodeRedemption() {
    const [activeTab, setActiveTab] = useState("1st");

    return (
        <div className="">
            <div className="jlt-flex jlt-items-center jlt-justify-center">
                <button
                    className={`jlt-py-3 jlt-px-6 jlt-font-semibold jlt-border-2 jlt-border-r-0 jlt-text-[#16213E] ${
                        activeTab === "1st"
                            ? "jlt-bg-[#0d49ce] jlt-text-white jlt-border-[#0d49ce]"
                            : ""
                    }`}
                    onClick={() => setActiveTab("1st")}>
                    1st Code Redeem
                </button>
                <button
                    className={`jlt-py-3 jlt-px-6 jlt-font-semibold jlt-border-2 jlt-border-l-0 jlt-text-[#16213E] ${
                        activeTab === "2nd"
                            ? "jlt-bg-[#0d49ce] jlt-text-white jlt-border-[#0d49ce]"
                            : ""
                    }`}
                    onClick={() => setActiveTab("2nd")}>
                    2nd & 3rd Code
                </button>
            </div>
            <div className="jlt-border jlt-mt-4 jlt-p-12 jlt-max-w-[900px] jlt-mx-auto jlt-bg-white">
                {activeTab === "1st" ? <FirstForm /> : null}
                {activeTab === "2nd" ? <SecondForm /> : null}
            </div>
        </div>
    );
}

export default JltAppsumoCodeRedemption;

const FirstForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);

    async function onSubmit(form) {
        const formData = {
            first_name: form.get("first_name"),
            last_name: form.get("last_name"),
            email: form.get("email"),
            appsumo_code: form.get("appsumo_code"),
            agree_terms: form.get("agree_terms"),
            agree_email: form.get("agree_email"),
        };

        const { data } = await axios.post(`/api/code-redemption`, formData);
        setIsLoading(false);
        if (data?.success) {
            handleToastr(data?.data?.output);
            formRef.current.reset();
        }

        if (!data?.success) {
            handleToastr(data?.data?.output || data?.message, "error");
        }
    }

    return (
        <form action={onSubmit} ref={formRef}>
            <div className="jlt-grid jlt-grid-cols-2 jlt-gap-4">
                <div>
                    <label
                        htmlFor="first_name"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        First Name
                    </label>
                    <input
                        required
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="First Name"
                        // onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        // value={formInfo.names.first_name}
                    />
                </div>
                <div>
                    <label
                        htmlFor="last_name"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        Last Name
                    </label>
                    <input
                        required
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        // onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        // value={formInfo.names.first_name}
                    />
                </div>
            </div>
            <div className="jlt-mt-6">
                <label
                    htmlFor="email"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    Email Address
                </label>
                <input
                    required
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    // onChange={handleOnChange}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                    // value={formInfo.names.first_name}
                />
            </div>
            <div className="jlt-mt-6">
                <label
                    htmlFor="appsumo_code"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    Appsumo Code
                </label>
                <input
                    required
                    type="text"
                    id="appsumo_code"
                    name="appsumo_code"
                    placeholder="Redeem AppSumo Code"
                    // onChange={handleOnChange}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                    // value={formInfo.names.first_name}
                />
            </div>

            <div className="jlt-mt-6 ">
                <label className="jlt-flex jlt-items-center">
                    <input
                        type="checkbox"
                        name="agree_terms"
                        id="agree_terms"
                        className="jlt-mr-2"
                    />
                    I agree to the{" "}
                    <a
                        href=" https://wpadminify.com/terms-and-conditions/"
                        target="_blank"
                        className="jlt-text-[#0d49ce]">
                        terms and conditions
                    </a>{" "}
                    of <strong>"WP Adminify"</strong>
                    <span className="field-required">*</span>
                </label>
                <label className="jlt-flex jlt-items-center jlt-mt-2">
                    <input
                        type="checkbox"
                        name="agree_email"
                        id="agree_email"
                        className="jlt-mr-2"
                    />
                    I want to receive emails about security updates, new features and offers of{" "}
                    <strong>"WP Adminify"</strong>
                </label>
            </div>

            <button
                onClick={() => {
                    setIsLoading(true);
                }}
                type="submit"
                className={`jlt-py-3 jlt-px-6 jlt-font-semibold jlt-border-2 jlt-border-l-0 jlt-bg-[#0d49ce] jlt-text-white jlt-border-[#0d49ce] jlt-mt-6 jlt-rounded jlt-uppercase jlt-flex jlt-gap-2 jlt-items-center`}>
                {isLoading ? <Loader type="button-loader" /> : null}
                Submit
            </button>
        </form>
    );
};

const SecondForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);

    async function onSubmit(form) {
        const formData = {
            email: form.get("email"),
            stack_codes: form.get("stack_codes"),
            agree_terms: form.get("agree_terms"),
            agree_email: form.get("agree_email"),
        };

        const { data } = await axios.post(`/api/code-stacking`, formData);
        setIsLoading(false);

        if (data?.success) {
            formRef.current.reset();
            handleToastr(data?.data?.output);
        }

        if (!data?.success) {
            handleToastr(
                data?.data?.output || "Something went wrong. Please try again." || data?.message,
                "error"
            );
        }
    }

    return (
        <form action={onSubmit} ref={formRef}>
            <div>
                <label
                    htmlFor="email"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    Email Address
                </label>
                <input
                    required
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    // onChange={handleOnChange}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                    // value={formInfo.names.first_name}
                />
            </div>
            <div className="jlt-mt-6">
                <label
                    htmlFor="stack_codes"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    Stack Appsumo Code
                </label>
                <input
                    required
                    type="text"
                    id="stack_codes"
                    name="stack_codes"
                    placeholder="Redeem AppSumo Code"
                    // onChange={handleOnChange}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                    // value={formInfo.names.first_name}
                />
            </div>

            <div className="jlt-mt-6 ">
                <label className="jlt-flex jlt-items-center">
                    <input
                        type="checkbox"
                        name="agree_terms"
                        id="agree_terms"
                        className="jlt-mr-2"
                    />
                    I agree to the{" "}
                    <a
                        href=" https://wpadminify.com/terms-and-conditions/"
                        target="_blank"
                        className="jlt-text-[#0d49ce]">
                        terms and conditions
                    </a>{" "}
                    of <strong>"WP Adminify"</strong>
                    <span className="field-required">*</span>
                </label>
                <label className="jlt-flex jlt-items-center jlt-mt-2">
                    <input
                        type="checkbox"
                        name="agree_email"
                        id="agree_email"
                        className="jlt-mr-2"
                    />
                    I want to receive emails about security updates, new features and offers of{" "}
                    <strong>"WP Adminify"</strong>
                </label>
            </div>

            <button
                onClick={() => {
                    setIsLoading(true);
                }}
                type="submit"
                className={`jlt-py-3 jlt-px-6 jlt-font-semibold jlt-border-2 jlt-border-l-0 jlt-bg-[#0d49ce] jlt-text-white jlt-border-[#0d49ce] jlt-mt-6 jlt-rounded jlt-uppercase jlt-flex jlt-gap-2 jlt-items-center`}>
                {isLoading ? <Loader type="button-loader" /> : null}
                Submit
            </button>
        </form>
    );
};
