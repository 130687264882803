const PricingTooltip = ({ content = "" }) => {
    return (
        <div className="jlt-pricing-tooltip-wrapper">
            <div className="jlt-pricing-tooltip-content jlt-absolute jlt-top-auto jlt-bottom-[42px] jlt-right-[16px] jlt-w-[90%] jlt-px-4 jlt-py-2 jlt-rounded-lg jlt-bg-[#fff]">
                <p className="jlt-text-xs jlt-font-medium jlt-leading-[18px]">{content}</p>
            </div>
            <span className="jlt-w-4 jlt-h-4 jlt-cursor-pointer">
                <img
                    className="jlt-w-[16px] jlt-h-[16px]"
                    src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/10062857/tooltip-icon.svg"
                    alt="tooltip action"
                />
            </span>
        </div>
    );
};

export default PricingTooltip;
