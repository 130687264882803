import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import axios from "axios";
import Rating from "react-star-review";
import Link from "next/link";
import { handleToastr } from "@litonarefin/utils/handleToastr";

const ReviewForm = ({ product_id, depth, session, productName }) => {
    const [spinner, setSpinner] = useState(false);
    const [review, setReview] = useState({
        product_id: product_id,
        review: "",
        rating: 5,
        title: "",
        reviewer_email: "",
        reviewer: "",
        status: "hold",
    });

    const handleChange = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        setReview({ ...review, [key]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            review?.review == "" ||
            review?.reviewer_email == "" ||
            review?.reviewer == ""
        ) {
            return;
        }

        setSpinner(true);

        let { data } = await axios.post("/api/reviews", review);
        if (data?.success) {
            // fetchReviewes(product_id, review?.reviewer);
            handleToastr("Review created Successfully!");
            setSpinner(false);
            setReview({
                ...review,
                review: "",
            });
        }
    };

    useEffect(() => {
        setReview({
            ...review,
            product_id: product_id,
            reviewer_email: session?.data?.user?.email,
            reviewer: session?.data?.user?.displayName,
        });
    }, [session, product_id]);

    return (
        <div style={{ marginLeft: `${depth * 50}px` }}>
            <div className="jlt-p-10 jlt-bg-white jlt-shadow-[0px_0px_60px_rgba(16,24,40,0.04)] jlt-rounded-lg">
                <h3 className="jlt-text-2xl jlt-text-[#1D2939] jlt-font-bold">
                    Review {productName || ""}
                </h3>
                <p className="jlt-mt-4 jlt-text-[#667085]">
                    Tell us and other users about your experience. If you have
                    issues with this product you can{" "}
                    <Link
                        href="/contact"
                        className="jlt-text-[--primary] jlt-underline">
                        contact support for help.
                    </Link>
                </p>

                <form className="jlt-mt-8">
                    <div className="jlt-flex jlt-items-center jlt-gap-8">
                        <h4 className="jlt-text-[#1D2939] jlt-text-lg jlt-font-bold">
                            Overall Rating
                        </h4>
                        <Rating
                            interactive={true}
                            onRatingChanged={(r) =>
                                setReview({ ...review, rating: r })
                            }
                            rating={0}
                            count={5}
                            clearColor="#e1e1e1"
                            borderColor="#F2F4F7"
                            filledColor="#FDB022"
                            hoverColor="#FDB022"
                        />
                    </div>
                    <hr className="jlt-my-6" />
                    <div>
                        <h3 className="jlt-text-[#475467] jlt-font-semibold jlt-text-base">
                            Review Title
                        </h3>
                        <input
                            id="title"
                            name="title"
                            type="text"
                            value={review?.title}
                            onChange={handleChange}
                            className="jlt-bg-[#FCFDFF] jlt-mt-2 jlt-w-full jlt-border jlt-border-[#EBEEF5] jlt-outline-0 jlt-py-2 jlt-px-4 jlt-text-[#16213E] jlt-font-semibold jlt-rounded-sm"
                        />
                    </div>
                    <div className="jlt-mt-4">
                        <label
                            htmlFor="review"
                            className="jlt-text-[#16213E] jlt-font-semibold jlt-block">
                            Your Review
                        </label>

                        <textarea
                            id="review"
                            name="review"
                            value={review?.review}
                            onChange={handleChange}
                            className="jlt-w-full jlt-h-32 jlt-mt-2 jlt-bg-[#FCFDFF] jlt-border jlt-border-[#EBEEF5] jlt-outline-0 jlt-p-5 jlt-text-[#16213E] jlt-rounded-sm"
                        />
                    </div>
                    <div className="submit-area jlt-flex jlt-gap-2">
                        <input
                            disabled={spinner}
                            type="submit"
                            value="Submit"
                            onClick={handleSubmit}
                            className="jlt-mt-[25px] jlt-py-[10px] jlt-px-4 jlt-bg-[--primary] jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-full jlt-text-sm jlt-text-[#FFFFFF] jlt-font-bold jlt-cursor-pointer"
                        />
                        <input
                            type="submit"
                            value="Cancel"
                            className="jlt-mt-[25px] jlt-py-[10px] jlt-px-4 jlt-bg-white jlt-rounded-full jlt-text-sm jlt-text-[--primary] jlt-font-bold jlt-cursor-pointer"
                        />
                        {spinner && (
                            <div className="action-loader">
                                <Loader />
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReviewForm;
