"use client";

import { getTypographyStyles } from "@litonarefin/utils/font";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import { getDimentionStyles } from "@litonarefin/utils/spacing";
import { Fragment } from "react";

const Button = ({ attributes }) => {
    const {
        isIconbtn,
        btnBgType,
        btnBgColor,
        btnBgGradient,
        btnColor,
        btnTypo,
        btnPadding = {
            top: "12px",
            right: "24px",
            bottom: "12px",
            left: "24px",
            openBox: "single-border",
        },
        btnURL,
        buttonText,
        btnBorderType,
        btnBorderColor,
        btnBorderRadius,
        btnBorderWidth,
        btnMarginTop,
        btnBgHType,
        btnBgColorHover,
        btnBgHGradient,
        btnColorHover,
        btnBorderColorHover,
        btnTransition,
    } = attributes;

    const { dimentionStyles: buttonPadding } = getDimentionStyles(
        btnPadding,
        "padding"
    );
    const { typography: btnTypography } = getTypographyStyles(btnTypo);

    return (
        <Fragment>
            <a
                className="mb-btn"
                href={btnURL?.url ? btnURL?.url : "#"}
                target={btnURL?.blank ? "_blank" : ""}
                rel={btnURL?.rel ? "nofollow noopener" : "noopener"}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: buttonText || "Learn More",
                    }}
                />
                {!!isIconbtn ? (
                    <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L14.5858 8H1C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H14.5858L10.2929 1.70711C9.90237 1.31658 9.90237 0.683417 10.2929 0.292893Z"
                            fill="black"
                        />
                    </svg>
                ) : null}
            </a>
            <style jsx>{`
                .mb-btn {
                    cursor: pointer;
                    box-sizing: border-box;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    ${btnColor
                        ? `color: ${btnColor};`
                        : `color: #fff;`} ${btnBgType === "clasic"
                        ? `background: ${btnBgColor};`
                        : `background: ${btnBgGradient};`} ${buttonPadding
                        ? buttonPadding
                        : ""} ${btnBorderRadius
                        ? `border-radius: ${btnBorderRadius}px;`
                        : "border-radius: 8px;"} ${btnBorderColor
                        ? `border-color: ${btnBorderColor};`
                        : ""} ${btnBorderWidth
                        ? `border-width: ${btnBorderWidth}px;`
                        : "border-width: 0px;"} ${btnBorderType !== "none"
                        ? `border-style: ${btnBorderType};`
                        : ""} ${btnMarginTop
                        ? `margin-top: ${btnMarginTop}px;`
                        : "margin-top: 0px;"} ${btnTypography
                        ? btnTypography
                        : ""} ${btnTransition
                        ? `transition: all ${btnTransition}ms;`
                        : ""} &:hover {
                        ${btnColorHover
                            ? `color: ${btnColorHover};`
                            : ""} ${btnBgHType === "clasic"
                            ? `background: ${btnBgColorHover};`
                            : `background: ${btnBgHGradient};`} ${btnBorderColorHover
                            ? `border-color: ${btnBorderColorHover};`
                            : ""} > svg path {
                            ${btnColorHover ? `fill: ${btnColorHover};` : ""}
                        }
                    }
                    > svg {
                        margin-left: 8px;
                    }
                    > svg path {
                        ${btnColor ? `fill: ${btnColor};` : "fill: #fff;"}
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Button;
