"use client";

import React, { useEffect, useState } from "react";
import RequredStar from "./RequredStar";

const Names = ({ fields, name }) => {
    const [fullName, setFullName] = useState({ first_name: "", middle_name: "", last_name: "" });

    useEffect(() => {
        setFullName({
            first_name: fields.first_name.attributes.value,
            middle_name: fields.middle_name.attributes.value,
            last_name: fields.last_name.attributes.value,
        });
    }, []);

    return (
        <div className="jlt-flex jlt-items-center jlt-gap-3">
            {fields.first_name.settings?.visible ? (
                <div className="jlt-w-full jlt-py-2">
                    <label htmlFor={fields.first_name.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        {fields.first_name.settings.label} <RequredStar field={fields.first_name} />
                    </label>
                    <input
                        required={fields.first_name.settings.validation_rules?.required?.value}
                        type={fields.first_name.attributes.type}
                        id={fields.first_name.attributes.name}
                        // name={fields.first_name.attributes.name}
                        placeholder={fields.first_name.attributes.placeholder}
                        value={fullName.first_name}
                        onChange={(e) => {
                            setFullName({ ...fullName, first_name: e.target.value });
                        }}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
                </div>
            ) : null}
            {fields.middle_name.settings?.visible ? (
                <div className="jlt-w-full jlt-py-2">
                    <label htmlFor={fields.middle_name.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        {fields.middle_name.settings.label} <RequredStar field={fields.middle_name} />
                    </label>
                    <input
                        required={fields.middle_name.settings.validation_rules?.required?.value}
                        type={fields.middle_name.attributes.type}
                        id={fields.middle_name.attributes.name}
                        // name={fields.middle_name.attributes.name}
                        placeholder={fields.middle_name.attributes.placeholder}
                        value={fullName.middle_name}
                        onChange={(e) => {
                            setFullName({ ...fullName, middle_name: e.target.value });
                        }}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
                </div>
            ) : null}
            {fields.last_name.settings?.visible ? (
                <div className="jlt-w-full jlt-py-2">
                    <label htmlFor={fields.last_name.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        {fields.last_name.settings.label} <RequredStar field={fields.last_name} />
                    </label>
                    <input
                        required={fields.last_name.settings.validation_rules?.required?.value}
                        type={fields.last_name.attributes.type}
                        id={fields.last_name.attributes.name}
                        // name={fields.last_name.attributes.name}
                        placeholder={fields.last_name.attributes.placeholder}
                        value={fullName.last_name}
                        onChange={(e) => {
                            setFullName({ ...fullName, last_name: e.target.value });
                        }}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"></input>
                </div>
            ) : null}

            <input className="jlt-hidden" name={name} defaultValue={getFullName(fullName)}></input>
        </div>
    );
};

export default Names;

function getFullName(fullName) {
    let name = "";
    if (fullName.first_name) {
        name = fullName.first_name;
    }
    if (fullName.middle_name) {
        name += " " + fullName.middle_name;
    }
    if (fullName.last_name) {
        name += " " + fullName.last_name;
    }

    return name;
}
