import React from "react";
import RequredStar from "./RequredStar";

const Select = ({ field }) => {
    return (
        <div className="jlt-w-full jlt-py-2">
            <label htmlFor={field.attributes.name} className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                {field.settings.label} <RequredStar field={field} />
            </label>
            <select
                // required={field.settings.validation_rules?.required?.value}
                multiple={field.attributes.multiple || false}
                type={field.attributes.type}
                id={field.attributes.name}
                name={field.attributes.name}
                className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                <option value="">--Select--</option>
                {field.settings.advanced_options.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select;
